import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as API from "../../api";

export const get_list = createAsyncThunk(
  "candidate/get_list",
  async (props, { rejectWithValue, signal }) => {
    try {
      // const controller = new AbortController()

      // signal.addEventListener("abort", () => controller.abort());

      // const { data, status } = await API.getApplicants(
      //   props.offset,
      //   props.limit,
      //   "",
      //   props.controller
      // );

      const { data, status } = await API.getResumeList(
        props.offset,
        props.limit,
        props.keyword,
        props.controller
      );

      return { data: data.data, message: data.message, status };
    } catch (err) {
      console.log(err);
      const { status } = err.response;
      const { data, message } = err.response.data;
      return rejectWithValue({ data, message, status });
    }
  }
);

export const candidateSlice = createSlice({
  name: "candidate",
  initialState: {
    loading: false,
    status: "idle",
    error: null,
    list: null,
    counts: { total: null },
  },
  extraReducers: {
    [get_list.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
      state.error = null;
    },
    [get_list.fulfilled]: (state, action) => {
      state.list = action.payload.data.resume
        ? [...action.payload.data.resume]
        : [];
      state.counts = { ...state.counts, total: action.payload.data.total_data };
      state.status = "idle";
      state.loading = false;
    },
    [get_list.rejected]: (state, action) => {
      state.error = { ...action.payload };
      state.status = "failed";
      state.loading = false;
    },
  },
});

export default candidateSlice.reducer;
