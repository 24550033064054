export const replaceAndSpace = (value) =>
  value
    .replaceAll(" And ", "-")
    .replaceAll(" and ", "-")
    .replaceAll(" & ", "-")
    .replaceAll(" / ", "-")
    .replaceAll("/", "-")
    .replaceAll(" ", "-")
    .toLowerCase();

export const ordinal_suffix = (n) =>
  ["st", "nd", "rd"][(((((n < 0 ? -n : n) + 90) % 100) - 10) % 10) - 1] || "th";

export const dial_codes = {
  ph: {
    code: "+63",
  },
  mx: {
    code: "+52",
  },
};

export const capitalizeString = (value) => {
  let string = value;
  string = string.charAt(0).toUpperCase() + string.slice(1);

  if (string.includes("")) {
    let arr = string.split("");
    arr.forEach((char, i) => {
      if (char === " " && arr[i + 1] !== undefined) {
        arr.splice(i + 1, 1, arr[i + 1]?.toUpperCase());
      }
    });

    string = arr.join("");
  }

  return string;
};

export const capitalizeFirstLetterString = (value) => {
  let string = value;

  string = string.charAt(0).toUpperCase() + string.slice(1);

  return string;
};

export const showLastThreeNumbersOnly = (number) => {
  let hideNum = [];

  for (let i = 0; i < number.length; i++) {
    if (i < number.length - 3) {
      hideNum.push("*");
    } else {
      hideNum.push(number[i]);
    }
  }

  return hideNum.join("");
};

export const isBase64 = (str) => {
  if (str.startsWith("data:")) {
    return true;
  } else {
    return false;
  }
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
