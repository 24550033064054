import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as API from "../../api";
import { setToLocalStorage } from "../../utils/localStorage";

export const verify_otp = createAsyncThunk(
  "verifyotp/verify_otp",
  async (props, { rejectWithValue }) => {
    try {
      const { data, status } = await API.verifyOtp(props);
      const newToken = await API.generateNewToken({
        user_id: data.data.user_id,
      });

      const modifiedMenu = data.data.menus.map((main) => {
        if (!Array.isArray(main.menu)) {
          return { ...main, menu: [main.menu] };
        }

        if (main.menu[0][0] !== undefined) {
          const menu = [];

          main.menu.forEach((item) => {
            item.forEach((item2) => {
              menu.push(item2);
            });
          });
          return { ...main, menu: menu };
        }

        return main;
      });

      const actualData = {
        ...data.data,
        menus: modifiedMenu,
        email: newToken.data.data?.email,
        location: newToken.data.data?.location,
        phone: newToken.data.data?.phone,
      };

      setToLocalStorage("st-admin-credentials", actualData);
      setToLocalStorage("st-admin-other-token", newToken.data.data.token);

      return { data: actualData, message: data.message, status };
    } catch (err) {
      const { status } = err.response;
      const { data, message } = err.response.data;
      return rejectWithValue({ data, message, status });
    }
  }
);

export const verifyotpSlice = createSlice({
  name: "verifyotp",
  initialState: {
    loading: false,
    status: "idle",
    error: null,
    user: null,
  },
  extraReducers: {
    [verify_otp.pending]: (state, action) => {
      state.status = "loading";
      state.loading = true;
      state.error = null;
    },
    [verify_otp.fulfilled]: (state, action) => {
      state.user = { ...action.payload.data };
      state.status = "idle";
      state.loading = false;
    },
    [verify_otp.rejected]: (state, action) => {
      state.error = { ...action.payload };
      state.status = "failed";
      state.loading = false;
    },
  },
});

export default verifyotpSlice.reducer;
