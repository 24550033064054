import { configureStore } from "@reduxjs/toolkit";

import auth from "./reducers/auth";
import candidate from "./reducers/candidate";
import client from "./reducers/client";
import joboffer from "./reducers/joboffer";
import verifyotp from "./reducers/verifyotp";

export default configureStore({
  reducer: {
    auth,
    candidate,
    client,
    joboffer,
    verifyotp
  },
});
