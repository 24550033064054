import React from "react";
import { Box, Container } from "@mui/material";

// local import
import BgWithName from "../../assets/st-bg-with-name.png";
import Bg from "../../assets/st-bg.png";
import PurpleBrandLogo from "../../assets/st-purple-logo.png";
import { useDataContext } from "../../context/DataContext";

const NoMenuLayout = ({ hasBgName, children }) => {
  const companyProfile = useDataContext();

  return (
    <>
      <Box
        height={80}
        display="flex"
        alignItems={"center"}
        bgcolor="common.white"
        paddingX={"30px"}
      >
        <img
          // style={{ width: 180 }}
          src={
            companyProfile?.companyProfile !== null
              ? "https://" + companyProfile?.companyProfile?.logo
              : PurpleBrandLogo
          }
          alt="Satellite Teams"
        />
      </Box>
      <Box
        height={"calc(100vh - 80px)"}
        sx={{
          background: hasBgName
            ? `url(${BgWithName}) no-repeat`
            : `url(${Bg}) no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            height: "100%",
          }}
        >
          {children}
        </Container>
      </Box>
    </>
  );
};

export default NoMenuLayout;
