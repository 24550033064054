import axios from "axios";

import { getUser, otherToken } from "../utils/localStorage";

const API = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});
const localAPI = axios.create({
  baseURL: "http://127.0.0.1:8000/api/",
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});
const ClientAPI = axios.create({
  baseURL: process.env.REACT_APP_CLIENT_API,
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});

ClientAPI.interceptors.request.use((req) => {
  const token = otherToken();

  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }

  return req;
});

API.interceptors.request.use((req) => {
  const user = getUser();
  const token = otherToken();

  if (
    req.isNotAdminApi ||
    [
      "/login/admin",
      "/search/filters",
      "/resume",
      "/applicant/search",
      "/meeting",
      "/users",
      "/redirect-google-calendar",
      "/menus",
      "/change-password",
      "/configuration/payment",
    ].includes(req.url) ||
    req.url.includes("/applicant/resume") ||
    req.url.includes("/applicant/search") ||
    req.url.includes("/admin/roles") ||
    req.url.includes("/admin/archive-roles") ||
    req.url.includes("/admin/users") ||
    req.url.includes("/admin/archive-user")
  ) {
    req.baseURL = process.env.REACT_APP_CLIENT_API;
    req.headers.Authorization = `Bearer ${token}`;
  } else if (user) {
    req.headers.Authorization = `Bearer ${user.token}`;
  }

  return req;
});

//get new token for applicant and client api
export const generateNewToken = (params) => API.post("/login/admin", params);

// configuration
export const getJobType = (controller) =>
  ClientAPI.get("/configuration/job-type", { signal: controller.signal });
export const getWorkPreference = (controller) =>
  ClientAPI.get("/configuration/work-preference", {
    signal: controller.signal,
  });
export const getJobRole = (controller) =>
  ClientAPI.get("/configuration/position", { signal: controller.signal });
export const getPositionCategories = (controller) =>
  ClientAPI.get("/configuration/job-position", { signal: controller.signal });

export const getScheduleShift = (controller) =>
  ClientAPI.get("/configuration/job-shift", { signal: controller.signal });

export const getJobExperience = (controller) =>
  ClientAPI.get(`/configuration/job-experience`, { signal: controller.signal });

export const getJobCareerLevel = (controller) =>
  ClientAPI.get(`/configuration/job-career-level`, {
    signal: controller.signal,
  });

export const getCountries = (controller) =>
  ClientAPI.get(`/country`, { signal: controller.signal });

export const getCompensation = (controller) =>
  ClientAPI.get(`/configuration/compensation`, { signal: controller.signal });

export const getLanguage = (controller) =>
  ClientAPI.get(`/configuration/languange`, { signal: controller.signal });

export const getRate = (controller) =>
  ClientAPI.get(`/configuration/rate`, { signal: controller.signal });

export const getProficiency = (controller) =>
  ClientAPI.get(`/configuration/proficiency`, { signal: controller.signal });

export const getEducation = (controller) =>
  ClientAPI.get(`/configuration/educational-stage`, {
    signal: controller.signal,
  });
export const getRegion = (id, controller) =>
  ClientAPI.get(`/region/${id}`, { signal: controller.signal });
export const getState = (id, controller) =>
  ClientAPI.get(`/state/${id}`, { signal: controller.signal });
export const getCity = (id, controller) =>
  ClientAPI.get(`/city/${id}`, { signal: controller.signal });
export const getBusinessType = (controller) =>
  API.get(`/get-industry-type`, { signal: controller.signal });
export const getTimeZones = (controller) =>
  ClientAPI.get(`/configuration/timezone-utc`, { signal: controller.signal });

// client/advance search api
export const Applicant_Resume = (query, params, controller) =>
  API.get(
    `/applicant/resume/${query}`,
    { params },
    {
      signal: controller?.signal,
    }
  );
export const Applicant_Interested = (params, controller) =>
  API.post(`/resume`, params, { signal: controller?.signal });
export const Applicant_Search = (query, params, controller) =>
  API.get(`/applicant/search${query}`, { params, signal: controller?.signal });
export const Applicant_Filters = (controller) =>
  API.get(`/search/filters`, { signal: controller?.signal });
export const Scheduler_Save = (params, controller) =>
  API.post(`/meeting`, params, { signal: controller?.signal });
export const Scheduler_Participants = (controller) =>
  API.get(`/users`, { signal: controller?.signal });
export const Scheduler_Google_Calendar_Save = (params) =>
  ClientAPI.post(`/google-calendar`, params);
export const Scheduler_Outlook_Calendar_Save = (params) =>
  ClientAPI.post(`/outlook-calendar`, params);
// client profile
export const getClientContacts = (id, controller) =>
  API.get(`/get-contact/${id}`, { signal: controller?.signal });
export const getClientMeetings = (id, controller) =>
  API.get(`/client-calendar/${id}`, { signal: controller?.signal });
export const getClientCompanyInfo = (id, controller) =>
  ClientAPI.get(`/admin/profile/company-information/${id}`, { signal: controller?.signal });

// client billing
export const getClientBilling = (id, controller) =>
  API.get(`/view-billing/${id}`, { signal: controller?.signal });
export const getBillingRules = (id, controller) =>
  API.get(`/client/billing/rules/${id}`, { signal: controller?.signal });
export const getBillingCutOff = (id, controller) =>
  API.get(`/billing/setup/${id}`, { signal: controller?.signal });
export const getBillingRulesList = (id, controller) =>
  API.get(`/billing/rules/${id}/1`, { signal: controller?.signal });

// client invoice
export const getClientInvoiceList = (query, params, controller) =>
  ClientAPI.get(`/admin/invoice/list${query}`, params, {
    signal: controller?.signal,
  });
export const getClientInvoiceSummary = (id, query, params, controller) =>
  ClientAPI.get(`/admin/invoice/get/${id}/summary${query}`, params, {
    signal: controller.signal,
  });

// account
// user
export const getUsers = (query, controller) => {
  if (query) {
    return API.get(`/admin/users/get${query}`, { signal: controller?.signal });
  }

  return API.get(`/admin/users/get`, { signal: controller?.signal });
};
export const viewUser = (id, controller) =>
  API.get(`/admin/users/get/${id}`, { signal: controller?.signal });
export const addUser = (params) => API.post(`/admin/users/save`, params);
export const updateUser = (params) => API.put("/admin/users/update", params);
export const softDeleteUser = (id, params) =>
  API.delete(`/admin/users/delete/${id}`, params);
export const changePasswordUser = (params) =>
  API.post(`/change-password`, params);
export const exportUser = (status, type, search) =>
  API.get(`/admin/users/export/${status}?type=${type}&search_input=${search}`, {
    responseType: "blob",
  });
// archive
export const getArchiveUsers = (query, controller) =>
  API.get(`/admin/archive-user${query}`, { signal: controller?.signal });
export const restoreArchiveUser = (id) => API.put(`/admin/archive-user/${id}`);
export const hardDeleteArchiveUser = (id) =>
  API.delete(`/admin/archive-user/${id}`);
// roles and permissions
export const getMenus = (controller) =>
  API.get(`/menus`, { signal: controller?.signal });
export const getRoles = (query, controller) => {
  if (query) {
    return API.get(`/admin/roles${query}`, { signal: controller?.signal });
  }

  return API.get(`/admin/roles`, { signal: controller?.signal });
};
export const viewRole = (id, controller) =>
  API.get(`/admin/roles/${id}`, { signal: controller?.signal });
export const addRole = (params) => API.post(`/admin/roles`, params);
export const updateRole = (params) => API.put("/admin/roles", params);
export const softDeleteRole = (id, params) =>
  API.delete(`/admin/roles/${id}`, params);
// archive
export const getArchiveRoles = (query, controller) =>
  API.get(`/admin/archive-roles${query}`, { signal: controller?.signal });
export const restoreArchiveRole = (id) => API.put(`/admin/archive-roles/${id}`);
export const hardDeleteArchiveRole = (id) =>
  API.delete(`/admin/archive-roles/${id}`);
export const exportRoles = (status, type, search, paginate) =>
  ClientAPI.get(
    `admin/roles/export/${status}?type=${type}&search_input=${search}&paginate=${paginate}`,
    {
      responseType: "blob",
    }
  );
// roles and permissions

// payment
export const getConfigurationPayment = (query, controller) =>
  API.get(`/configuration/payment`, { signal: controller?.signal });

// hr settings
export const getPositionJobList = (offset, entries, search, controller) =>
  API.get(`/job-list?offset=${offset}&limit=${entries}&search=${search}`, {
    signal: controller?.signal,
  });
export const sendOnboardingForm = (id) =>
  API.get(`/onboarding/send/${id}`);
// get request end

// post request
export const googleCalendar = (formData) =>
  ClientAPI.post("/google-calendar", formData);
export const loginUser = (params) => API.post("/login", params);
export const forgotPassword = (formData) =>
  API.post("/forgot-password", formData);
export const checkToken = (formData) => API.post("/check-token", formData);
export const resetPassword = (formData) =>
  API.post("/reset-password", formData);
export const verifyOtp = (params) => API.post("/verify-code", params);
export const resendOtp = (params) => API.post("/resend-otp", params);
export const jobOffer = (formData) => API.post("/job-offer", formData);
export const deleteOtherField = (id) => API.delete(`/job-offer/field/delete/${id}`);
export const sendEmailVerification = (userId) =>
  ClientAPI.post("/applicant/email", { user_id: userId });

// management client
export const deleteClientContactPerson = (id) =>
  API.post(`/delete-contact/${id}`);
export const saveClientBillingSettings = (formData) =>
  API.post("/client/billing/rules", formData);
export const addClientContact = (params) => API.post("/add-contact", params);

// management candidate
export const candidateRegistration = (formData) =>
  ClientAPI.post("/applicant", formData);
export const saveCandidateResume = (formData) =>
  ClientAPI.post("/resume/store", formData);
export const registerClient = (formData) =>
  ClientAPI.post("/admin/client/add", formData);
export const sendResume = (formData) => API.post("/resume/send", formData);

// hr management
export const saveAppraisal = (formData) =>
  API.post("/appraisal/save", formData);
export const saveEmployeeRequest = (formData) =>
  API.post("/hired/request", formData);
export const saveEmployeeFolder = (formData) =>
  ClientAPI.post("/employee/folder", formData);
export const saveEmployeeDocument = (formData) =>
  ClientAPI.post("/employee/folder/upload/document", formData);
export const requestResetPassword = (id) =>
  API.put(`/hired/reset-password/${id}`)

// hr settings - jobtypes - skills
export const saveSkill = (params) => API.post("/hard-skills", params);

// hr settings - jobtypes - category
export const getAllPositionCategories = (offset, entries, search, controller) =>
  API.get(
    `/get-all-position-categories?offset=${offset}&limit=${entries}&search=${search}`,
    { signal: controller?.signal }
  );
export const addPositionCategories = (params) =>
  API.post("/add-position-categories", params);
export const updatePositionCategories = (id, params) =>
  API.patch(`/update-position-categories/${id}`, params);
export const archivePositionCategories = (id) =>
  API.patch(`/archive-position-categories/${id}`);
export const deletePermanentPositionCategories = (id) =>
  API.delete(`/delete-position-categories/${id}`);
export const getAllArchivedPositionCategories = (
  offset,
  entries,
  search,
  controller
) =>
  API.get(
    `/get-all-archive-position-categories?offset=${offset}&limit=${entries}&search=${search}`,
    { signal: controller?.signal }
  );
export const restorePositionCategories = (id) =>
  API.patch(`/restore-position-categories/${id}`);

// hr settings - jobtypes - job role
export const getAllJobRole = (
  category_id,
  archive,
  offset,
  entries,
  search,
  controller
) =>
  API.get(
    `/job-position/${category_id}/${archive}?page=${offset}&paginate=${entries}&search=${search}`,
    {
      signal: controller?.signal,
    }
  );
export const addJobRole = (params) => API.post("/job-roles", params);
export const updateJobRole = (id, params) =>
  API.patch(`/job-roles/${id}`, params);
export const deletePermanentJobRole = (id) => API.delete(`/job-roles/${id}`);
export const getAllArchivedJobRole = (offset, entries, search, controller) =>
  API.get(
    `/get-role-archive?offset=${offset}&limit=${entries}&search=${search}`,
    { signal: controller?.signal }
  );
export const restoreJobRole = (id) => API.patch(`/restore-job-roles/${id}`);
export const getAllJobRoleApproval = (offset, entries, search, controller) =>
  API.get(
    `/for-approval/job-list?offset=${offset}&limit=${entries}&search=${search}`,
    {
      signal: controller?.signal,
    }
  );
export const getAllArchivedJobRoleApproval = (
  offset,
  entries,
  search,
  controller
) =>
  API.get(
    `/for-approval/archive-job-list?offset=${offset}&limit=${entries}&search=${search}`,
    { signal: controller?.signal }
  );
export const softDeleteJobRoleApproval = (id) =>
  API.patch(`/for-approval/archive-job-role/${id}`);
export const restoreJobRoleApproval = (id) =>
  API.patch(`/for-approval/reactivate-job-role/${id}`);
export const hardDeleteJobRoleApproval = (id) =>
  API.delete(`/for-approval/delete-job-role/${id}`);
export const updateJobRoleApproval = (id, params) =>
  API.patch(`/for-approval/update-job-role/${id}`, params);

// acount
// roles
export const listRole = (query, controller) =>
  API.get(`/get-all-role${query}`, { signal: controller?.signal });

// asset management
// list
export const listAssetManagement = (query, controller) =>
  API.get(`/get-all-assets${query}`, { signal: controller?.signal });
export const addAssetManagement = (params) => API.post("/add-asset", params);
export const updateAssetManagement = (id, params) =>
  API.patch(`/update-asset/${id}`, params);
export const searchSupplierAssetManagement = (params, controller) =>
  API.post("/search-vendor", params, { signal: controller.signal });
export const searchEmployeeHiredAssetManagement = (type, params, controller) =>
  API.post(`/search-hired/${type}`, params, { signal: controller.signal });
export const searchClientAssetManagement = (params, controller) =>
  API.post("/search-client", params, { signal: controller.signal });
export const searchAssetByTrackingNumberAssetManagement = (
  trackNo,
  controller
) => API.get(`/search-trackno/${trackNo}`, { signal: controller.signal });
export const archiveAssetManagement = (id) =>
  API.patch(`/archive-asset/${id}`, { deleted: 1 });
export const restoreAssetManagement = (id) =>
  API.patch(`/reactivate-asset/${id}`, { deleted: 0 });
export const hardDeleteAssetManagement = (id) =>
  API.delete(`/delete/asset/${id}`);
export const sendFormNotification = (id) => API.get(`/asset/send/form/${id}`);
export const downloadAsset = (query) =>
  API.get(`/get-all-assets${query}`, {
    responseType: "blob",
  });
export const uploadAssetList = (params) =>
  API.post("/upload-bulk-asset", params);
export const getAssetInventoryList = () =>
  API.get("/asset/inventory/list");

// request
export const listRequest = (query, controller) =>
  API.get(`/assets/requests/get-assets${query}`, {
    signal: controller?.signal,
  });
export const addRequest = (params) =>
  API.post("/assets/requests/add-asset", params);
export const updateRequest = (id, params) =>
  API.patch(`/assets/requests/update-asset/${id}`, params);
export const changeRequest = (id, params) =>
  API.patch(`/assets/requests/change-status/${id}`, params);
export const archiveRequest = (id) =>
  API.patch(`/assets/requests/archive-asset/${id}`);
export const restoreRequest = (id) =>
  API.patch(`/assets/requests/reactivate-asset/${id}`);
export const hardDeleteRequest = (id) =>
  API.delete(`/assets/requests/delete/asset/${id}`);
// settings
// vendor
export const listVendor = (query, controller) =>
  API.get(`/get-vendor${query}`, { signal: controller?.signal });
export const addVendor = (params) => API.post("/add-vendor", params);
export const uploadAssetVendor = (params) =>
  API.post("/upload-asset-vendor", params);
export const updateVendor = (id, params) =>
  API.patch(`/update-vendor/${id}`, params);
export const archiveVendor = (id) =>
  API.patch(`/archive-vendor/${id}`, { deleted: 1 });
export const reactivateVendor = (id) =>
  API.patch(`/reactive-vendor/${id}?deleted=0`);
export const deletePermanently = (id) => API.patch(`/delete-permanently/${id}`);
export const searchvendor = (params) => API.patch(`/search-vendor`, { params });
export const assetDownload = (query) =>
  API.get(`/get-vendor${query}`, {
    responseType: "blob",
  });
// status
export const listStatus = (query, controller) => {
  if (query) {
    return API.get(`/get-status${query}`, { signal: controller?.signal });
  } else {
    return API.get(`/get-status`, { signal: controller?.signal });
  }
};
export const addStatus = (params) => API.post("/add-status", params);
export const updateStatus = (id, params) =>
  API.patch(`/update-status/${id}`, params);
export const assetStatusDownload = (query) =>
  API.get(`/get-status${query}`, {
    responseType: "blob",
  });
// product
export const listProduct = (query, controller) => {
  if (query) {
    return API.get(`/assets/get-product-services${query}`, {
      signal: controller?.signal,
    });
  } else {
    return API.get(`/assets/get-product-services`, {
      signal: controller?.signal,
    });
  }
};
export const assetProductDownload = (query) =>
  API.get(`/assets/get-product-services${query}`, {
    responseType: "blob",
  });
export const addProduct = (params) =>
  API.post("/assets/add-product-services", params);
export const updateProduct = (id, params) =>
  API.patch(`/assets/update-product-services/${id}`, params);
export const archiveListProduct = (query, controller) =>
  API.get(`/assets/archive-list-product-services${query}`, {
    signal: controller?.signal,
  });
export const archiveProduct = (id) =>
  API.patch(`/assets/archive-product-services/${id}`);
export const restoreProduct = (id) =>
  API.patch(`/assets/reactive-product-services/${id}`);
export const hardDeleteProduct = (id) =>
  API.delete(`/assets/delete/permanently-product-services/${id}`);

// type
export const listType = (query, controller) => {
  if (query) {
    return API.get(`/assets/get-types${query}`, { signal: controller?.signal });
  } else {
    return API.get(`/assets/get-types`, { signal: controller?.signal });
  }
};
export const addType = (params) => API.post("/assets/add-types", params);
export const updateType = (id, params) =>
  API.patch(`/assets/update-types/${id}`, params);
export const archiveListType = (query, controller) =>
  API.get(`/assets/archive-list-types${query}`, { signal: controller?.signal });
export const archiveType = (id) => API.patch(`/assets/archive-types/${id}`);
export const restoreType = (id) => API.patch(`/assets/reactive-types/${id}`);
export const hardDeleteType = (id) =>
  API.delete(`/assets/delete/permanently-types/${id}`);

// request status
export const listRequestStatus = (query, controller) => {
  if (query) {
    return API.get(`/assets/requests/get-status${query}`, {
      signal: controller?.signal,
    });
  } else {
    return API.get(`/assets/requests/get-status`, {
      signal: controller?.signal,
    });
  }
};
export const assetRequestStatusDownload = (query) =>
  API.get(`/assets/requests/get-status${query}`, {
    responseType: "blob",
  });
export const addRequestStatus = (params) =>
  API.post("/assets/requests/add-status", params);
export const updateRequestStatus = (id, params) =>
  API.patch(`/assets/requests/update-status/${id}`, params);
export const setPrimaryRequestStatus = (id, params) =>
  API.patch(`/assets/requests/change-primary/${id}`, params);
// post request end

// put request start
// management candidate
export const updateCandidateResume = (formData) =>
  ClientAPI.put("/resume/update ", formData);
export const updateResumeConfig = (params) =>
  ClientAPI.put("/admin/resume/config", params);
// management client
export const updateCompensation = (id, params) =>
  API.put(`/request/compensation/${id}`, params);
export const addCompensation = (params) =>
  API.post("/request/compensation", params);
// management hr
export const updateUserProfile = (formData) =>
  ClientAPI.put("/employee/profile", formData);
export const updateUserCredentials = (formData) =>
  ClientAPI.put("/employee/user", formData);
export const updateStatusInterviewAndJoList = (params) =>
  API.put("/interview/update", params);
export const cancelJobOffer = (id, params) =>
  API.put(`/update-job-offer/${id}`, params);
export const updateEmployeeStatusHiredList = (params) =>
  API.put("/hired/update", params);
export const updateEmployeeCompany = (params) =>
  API.put("hired/update-company", params);
export const updateFiling = (id, params) =>
  ClientAPI.put(`employee/fillings/${id}`, params);
export const updateEnglishAssessmentConfigCategory = (id, params) =>
  API.put(`/speaking-test/config/${id}/category`, params);
export const updateEmployeeFolder = (id, params) =>
  ClientAPI.put(`/employee/folder/${id}`, params);
export const updateEmployeeDocument = (id, params) =>
  ClientAPI.put(`/employee/folder/update/document/${id}`, params);
export const cancelOnboarding = (id, params) =>
  API.put(`/on-boarding/cancelled/${id}`, params);
export const cancelOffboarding = (params) =>
  API.put(`/hired/update`, params);
export const updateDocumentVisibility = (id, type, params) =>
  ClientAPI.put(`/employee/folder/visibility/${id}/${type}`, params);
export const updateSalary = (id, params) =>
  API.put(`/hired/update-salary/${id}`, params);
// put request end

// patch request start
// change password
export const changeFirstLoginPassword = (formData) =>
  API.patch(`/admin/change-password`, formData);

// management client
export const updateClientCompanyInformation = (formData, id) =>
  API.patch(`/update-client/${id}`, formData);
export const updateClientContactPerson = (formData, id) =>
  API.patch(`/update-contact/${id}`, formData);
export const sendClientToArchive = (id) =>
  API.patch(`/archive-client/${id}?deleted=1`);
export const suspendClient = (id) =>
  API.patch(`/suspend-client/${id}?deleted=2`);
export const reactivateClient = (id) =>
  API.patch(`/reactivate-client/${id}?deleted=0`);

//job types - skills
export const updateSkill = (id, params) =>
  API.patch(`/hard-skills/${id}`, params);
export const restoreSkill = (id) => API.patch(`/restore-hard-skills/${id}`);
// patch request end

// get request
// dashboard
export const getDashboardAllTotal = (start_date, end_date, controller) =>
  API.get(`/view-all-total?start_date=${start_date}&end_date=${end_date}`, {
    signal: controller.signal,
  });
export const getDashboardStatHired = (date, controller) =>
  API.get(`/dashboard/get/statistic?date=${date}`, {
    signal: controller.signal,
  });
export const getDashboardCounts = (controller) =>
  API.get(`/dashboard/get/count`, {
    signal: controller.signal,
  });
export const getDashboardRoles = (start_date, end_date, controller) =>
  API.get(`/view-job-roles?start_date=${start_date}&end_date=${end_date}`, {
    signal: controller.signal,
  });
export const getMyStatisticsData = (query, controller) =>
  API.get(`/agent/get-applicants/${query}`, {
    signal: controller.signal,
  });
export const getJobRequisitionCount = (start_date, end_date, controller) =>
  API.get(
    `/dashboard/job-requisition/count?start=${start_date}&end=${end_date}`,
    {
      signal: controller.signal,
    }
  );

// management client
export const getClients = (controller) =>
  API.get("/view-total-clients", { signal: controller.signal });

export const getSuggestiveClientList = (query, controller) =>
  API.get(`get-all-clients${query}`, { signal: controller?.signal });

export const getClientList = (limit, page, keyword, controller) =>
  API.get(
    `get-all-clients?paginate=${limit}&page=${page}&search=${keyword}&status=archive`,
    null,
    {
      signal: controller,
    }
  );
export const getArchivedClientList = (limit, page, keyword, controller) =>
  API.get(
    `clients/archives?paginate=${limit}&page=${page}&search=${keyword}&status=archive`,
    null,
    {
      signal: controller,
    }
  );
export const generateClientList = (search, type, controller) =>
  API.get(
    `/client/export?search=${search}&type=${type}`,
    {
      responseType: "blob",
    },
    {
      signal: controller,
    }
  );
export const getCompensationList = (type, limit, page, keyword, controller) =>
  API.get(
    `/request/compensation?type=${type}&paginate=${limit}&page=${page}&search=${keyword}`,
    null,
    {
      signal: controller,
    }
  );
export const getAllClients = (controller) =>
  API.get("/client/list", { signal: controller?.signal });
export const generateBilling = (client_id) =>
  ClientAPI.get(`/admin/billing/generate/${client_id}`);
export const getCompanyEmployees = (type, client_id) =>
  API.get(type === "external" ? (client_id ? `/employee/list/EXTERNAL/${client_id}` : "/employee/list/EXTERNAL") : "/employee/list/INTERNAL");
// management candidate
export const getApplicants = (offset, limit, status, controller) =>
  API.get(
    `/view-total-applicants?offset=${offset}&limit=${limit}&status=${status}`,
    { signal: controller?.signal }
  );
export const getResumeList = (
  page,
  limit,
  keyword,
  filter,
  redFlag,
  params,
  controller
) =>
  ClientAPI.get(
    `/resume/list/${redFlag}?page=${page}&paginate=${limit}&search_input=${keyword}&filter=${filter}`,
    // null,
    { params, signal: controller?.signal }
  );
export const getResumeDetails = (userId, controller) =>
  ClientAPI.get(`/resume/${userId}`, { signal: controller.signal });
export const getProfileDetails = (userId, controller) =>
  ClientAPI.get(`/profile/${userId}`, { signal: controller.signal });
export const getCandidateDocuments = (userId, controller) =>
  ClientAPI.get(`/document/${userId}`, { signal: controller.signal });
export const getHiredApplicants = (controller) =>
  API.get("/view-total-hired", { signal: controller.signal });
export const downloadResume = (id, params) =>
  ClientAPI.get(`/download/resume/${id}`, { params, responseType: "blob" });
export const downloadResumeDoc = (id, params) =>
  ClientAPI.get(`/download/resume/word/${id}`, {
    params,
    responseType: "blob",
  });

export const generateCandidateListDownload = (
  type,
  printType,
  page,
  limit,
  filter,
  redFlag,
  params,
  controller
) =>
  ClientAPI.get(
    `/resume/export/${redFlag}?type=${type}&filter=${filter}${printType === "page" ? `&paginate=${limit}` : ""
    }${printType === "page" ? `&page=${page}` : ""}`,
    {
      params,
      responseType: "blob",
    },
    {
      signal: controller.signal,
    }
  );

//management hr
export const generateJobOffer = (
  id,
  basic_salary,
  rice_allowance,
  communication_allowance,
  sick_leave,
  vaction_leave,
  work_hours,
  start_date,
  country
) =>
  API.get(
    `/generate-pdf?id=${id}&basic_salary=${basic_salary}&rice_allowance=${rice_allowance}&communication_allowance=${communication_allowance}&sick_leave=${sick_leave}&vaction_leave=${vaction_leave}&work_hours=${work_hours}&start_date=${start_date}&country=${country}`,
    {
      responseType: "blob",
    }
  );

export const generateJobOfferMx = (id) =>
  API.get(
    `/generate-pdf?id=${id}&country=MX`,
    {
      responseType: "blob",
    }
  );

export const generateJobOfferCo = (
  id,
  country,
  form,
  form_lang
) =>
  API.get(
    `/generate-pdf?id=${id}&country=${country}&form=${form}&form_lang=${form_lang}`,
    {
      responseType: "blob",
    }
  );
export const sendJobOffer = (id, params) =>
  API.get(`/send/jo/${id}`, { params });
export const sendEmailNotif = (id, formData, controller) =>
  API.post(`/send-job-offer/${id}`, formData, { signal: controller.signal });
export const getInterviewAndJoList = (
  status,
  type,
  page,
  limit,
  keyword,
  controller
) =>
  API.get(
    `/interview/list/${status}/${type}?page=${page}&paginate=${limit}&search=${keyword}`,
    { signal: controller?.signal }
  );
export const downloadlist = (status, canType, type, search) =>
  API.get(
    `/interview/export/${status}/${canType}?search=${search}&type=${type}`,
    {
      responseType: "blob",
    }
  );
export const getJODefaultParams = () => API.get("/job-offer/default");
export const getCreateJobOfferData = (id, controller) =>
  API.get(`/job-offer/get/${id}`, { signal: controller.signal });
export const getHiredList = (
  type,
  page,
  limit,
  keyword,
  controller,
  status,
  interview_status
) =>
  API.get(
    `/hired/list/${type}/${status}?page=${page}&paginate=${limit}&search=${keyword}&interview_status=${interview_status}`,
    { signal: controller?.signal }
  );
export const getPreviousHiredList = (type, page, limit, keyword, controller) =>
  API.get(
    `/employee/previous/list/${type}?page=${page}&paginate=${limit}&search=${keyword}`,
    { signal: controller?.signal }
  );
export const getFillingList = (
  user_id,
  page,
  limit,
  keyword,
  fromDate,
  toDate,
  controller
) =>
  ClientAPI.get(
    `/employee/fillings?user_id=${user_id}&page=${page}&paginate=${limit}&search_input=${keyword}&from=${fromDate}&to=${toDate}`,
    { signal: controller?.signal }
  );
export const downloadHiredList = (status, type, search) =>
  API.get(`/hired/export/${status}?type=${type}&search=${search}`, {
    responseType: "blob",
  });
export const getHiredInformation = (id, controller) =>
  API.get(`/hired/get/${id}`, { signal: controller?.signal });
export const getResumeAndInterviewDetails = (
  userId,
  interview_id,
  controller
) =>
  ClientAPI.get(`/resume/${userId}?interview_id=${interview_id}`, {
    signal: controller.signal,
  });
export const getAppraisalField = (type, controller) =>
  API.get(`/appraisal/fields/${type}`, { signal: controller?.signal });
export const getAppraisalList = (type, id) =>
  API.get(`/appraisal/list/${type}/${id}`);
export const getAppraisalConfig = (id) =>
  API.get(`/appraisal/get/${id}`);
export const getAppraisalComputation = (id, params) =>
  API.get(`/appraisal/computation/${id}${params}`);

// events
export const getAdminEvents = (id, controller) =>
  API.get(`/users-calendar-events/${id}`, { signal: controller?.signal });

//job types - skills
export const getAllPositionsSkills = (controller) =>
  API.get("/hard-skills", { signal: controller?.signal });
export const getAllSkills = (query, controller) =>
  ClientAPI.get(`/configuration/job-position${query}`, {
    signal: controller?.signal,
  });
export const getAllArchivedSkills = (page, entries, search, controller) =>
  API.get(
    `/get/hardskills-archive?offset=${page}&limit=${entries}&search=${search}`,
    { signal: controller?.signal }
  );
export const getEmployeeDocument = (
  user_id,
  archive,
  page,
  entries,
  search,
  controller
) =>
  ClientAPI.get(
    `/employee/folder/${user_id}/${archive}?page=${page}&paginate=${entries}&search_input=${search}`,
    { signal: controller?.signal }
  );
// get request end

//system configuration
//department
export const getAllDepartment = (query, controller) =>
  API.get(`/get-all-departments${query}`, { signal: controller?.signal });
export const getArchiveDepartment = (query, controller) =>
  API.get(`/get-department/archive${query}`, { signal: controller?.signal });
export const getArchiveSubDepartment = (query, controller) =>
  API.get(`/get-subdepartment/archive${query}`, { signal: controller?.signal });
export const getAllSubDepartment = (query, controller) =>
  API.get(`/get-all-subdepartments${query}`, { signal: controller?.signal });
export const addDepartment = (params) => API.post("/add-department", params);
export const addSubDepartment = (params) =>
  API.post("/add-subdepartment", params);
export const updateDepartment = (id, params) =>
  API.patch(`/update-department/${id}`, params);
export const updateSubDepartment = (id, params) =>
  API.patch(`/update-subdepartment/${id}`, params);
export const archiveDepartment = (id, params) =>
  API.patch(`/archive-department/${id}`, params);
export const archiveSubDepartment = (id, params) =>
  API.patch(`/archive-subdepartment/${id}`, params);
export const deleteDepartment = (id) => API.delete(`/delete-department/${id}`);
export const deleteSubDepartment = (id) =>
  API.delete(`/delete-subdepartment/${id}`);

//search config
export const getClientListSearchConfig = (query, controller) =>
  ClientAPI.get(`admin/search-configuration${query}`, {
    signal: controller?.signal,
  });
export const saveClientListSearchConfig = (params) =>
  ClientAPI.post("/admin/search-configuration", params);
export const getBudgetList = () => ClientAPI.get("/configuration/job-budget");
export const getClientSearchConfig = (id) =>
  ClientAPI.get(`/admin/search-configuration/${id}`);
export const deleteSearchConfig = (id) =>
  ClientAPI.delete(`/admin/search-configuration/${id}`);
export const getSalaryRate = () => ClientAPI.get("/configuration/salary-rate");
//country
export const getCountryList = (query, controller) =>
  API.get(`/configuration/list/country/${query}`, {
    signal: controller?.signal,
  });
export const addCountry = (params) =>
  API.post("/configuration/country", params);
export const updateCountry = (id, params) =>
  API.put(`/configuration/country/${id}`, params);
export const deleteCountry = (id, type) =>
  API.delete(`/configuration/country/${id}/${type}`);

//language
export const getLanguageList = (query, controller) =>
  API.get(`/configuration/list/language/${query}`, {
    signal: controller?.signal,
  });
export const addLanguage = (params) =>
  API.post("/configuration/language", params);
export const updateLanguage = (id, params) =>
  API.put(`/configuration/language/${id}`, params);
export const deleteLanguage = (id, type) =>
  API.delete(`/configuration/language/${id}/${type}`);

//forex
export const getForexList = (query, controller) =>
  API.get(`/configuration/list/forex/${query}`, { signal: controller?.signal });
export const addForex = (params) => API.post("/configuration/forex", params);
export const updateForex = (id, params) =>
  API.put(`/configuration/forex/${id}`, params);
export const deleteForex = (id, type) =>
  API.delete(`/configuration/forex/${id}/${type}`);
export const downloadForex = (type) =>
  API.get(`/configuration/list/forex-export/${type}`);

//recommendation
export const getRecommendationList = (query, controller) =>
  API.get(`/configuration/list/recommendation/${query}`, {
    signal: controller?.signal,
  });
export const addRecommendation = (params) =>
  API.post("/configuration/recommendation", params);
export const updateRecommendation = (id, params) =>
  API.put(`configuration/recommendation/${id}`, params);
export const deleteRecommendation = (id, type) =>
  API.delete(`/configuration/recommendation/${id}/${type}`);
//end system configuration

// start delete method
//job types - skills
export const deletePermanentSkill = (id) => API.delete(`/hard-skills/${id}`);
//client management
export const deletePermanentClient = (id) => API.delete(`/delete-client/${id}`);
//hr management
export const deleteEmployeeFolder = (id, type) =>
  ClientAPI.delete(`/employee/folder/${id}/${type}`);
export const deleteEmployeeDocument = (id, type) =>
  ClientAPI.delete(`/employee/folder/delete/document/${id}/${type}`);
// end delete method

// zoom meeting
export const getZoomScheduleView = (id, controller) =>
  ClientAPI.get(
    `/admin/meeting/${id}`,
    { data: null },
    { signal: controller?.signal }
  );
export const getZoomApplicantResume = (query, controller) =>
  ClientAPI.get(`/applicant/resume/${query}`, null, {
    signal: controller?.signal,
  });
export const getZoomActualSkill = (query, controller) =>
  ClientAPI.get(`/job/role/${query}`, null, {
    signal: controller?.signal,
  });
export const postZoomMeeting = (params) =>
  ClientAPI.post("/admin/interview", params);
export const zoomApplicantInterested = (params) =>
  ClientAPI.post("/resume", params);
export const getUsersList = (controller) =>
  ClientAPI.get("/admin/users/get", null, {
    signal: controller?.signal,
  });

//client documents
export const getClientDocuments = (query, controller) =>
  ClientAPI.get(`/client/document/${query}`, { signal: controller.signal });
export const addClientDocuments = (params) =>
  ClientAPI.post(`/client/document`, params);
export const deleteClientDocuments = (id, formData) =>
  API.delete(
    `/client/delete-documents/${id}
  `,
    formData
  );

// client job requirements
export const getJobRequirementFields = (controller) =>
  ClientAPI.get("/job-requirement/fields", { signal: controller.signal });
export const addClientJobRequirements = (params) =>
  ClientAPI.post(`/admin/job`, params);
export const getJobRequirementsList = (
  id,
  page,
  limit,
  search,
  archive,
  controller
) =>
  ClientAPI.get(
    `/admin/job?page=${page}&paginate=${limit}&search_input=${search}&archive=${archive}&user_id=${id}`,
    { signal: controller.signal }
  );
export const getJobRequirement = (id, controller) =>
  ClientAPI.get(`/admin/job/${id}`, { signal: controller.signal });
export const getJobRequirementsArchivedList = (
  id,
  page,
  limit,
  search,
  controller
) =>
  API.get(
    `/client/archive-job-requirement/${id}?offset=${page}&limit=${limit}&search=${search}`,
    { signal: controller.signal }
  );
export const updateJobRequirement = (params) =>
  ClientAPI.put(`/admin/job`, params);
export const deleteJobRequirement = (id) =>
  ClientAPI.delete(`/admin/job/${id}/1`);
export const restoreJobRequirement = (id) =>
  ClientAPI.delete(`/admin/job/${id}/0`);
export const deletePermanentJobRequirement = (id) =>
  ClientAPI.delete(`/admin/job/${id}/2`);

// client users
export const getClientUsersList = (id, page, paginate, search, archive, controller) =>
  API.get(
    `/client/users/list/${id}/${archive}?page=${page}&paginate=${paginate}&search=${search}`,
    { signal: controller.signal }
  );
export const getClientUser = (id, controller) =>
  API.get(`/client/user/get/${id}`, { signal: controller.signal });
export const updateClientUser = (id, params) => API.put(`/client/user/update/${id}`, params);
export const archiveClientUser = (id, type) =>
  API.delete(`/client/user/delete/${id}/${type}`);
export const getClientRoleList = (id, controller) =>
  API.get(`/client/roles/list/${id}`, { signal: controller.signal });
export const resetClientUser = (id) =>
  API.put(`/client/user/reset/${id}`);

// interview history
export const addReviewRating = (formData) =>
  ClientAPI.put(`/admin/rating`, formData);

// candidate portfolio
export const savePortfolio = (formData) =>
  API.post(`/portfolio/save`, formData);
export const getPortfolioList = (id, controller) =>
  API.get(`/portfolio/get/${id}`, { signal: controller.signal });
export const deletePortfolio = (id) => API.delete(`/portfolio/delete/${id}`);
export const updatePortfolio = (id, params) =>
  API.put(`/portfolio/update/${id}`, params);

//zoom meeting scheduler
export const saveZoomInterviewMeeting = (params) =>
  ClientAPI.post("/admin/meeting", params);
export const saveZoomInterviewMeetingClient = (params) =>
  ClientAPI.post("/meeting", params);
export const getClientZoomUsersList = (id, controller) =>
  API.get(`/client/users/${id}`, {
    signal: controller.signal,
  });
export const cancelZoomMeeting = (id) =>
  API.patch(`/cancel-calendar-events/${id}`);
export const getZoomMeetingDetails = (id, controller) =>
  ClientAPI.get(`/admin/meeting/${id}`, { signal: controller.signal });
export const updateZoomInterviewMeeting = (params) =>
  ClientAPI.put("/admin/meeting", params);
export const updateZoomInterviewMeetingClient = (params) =>
  ClientAPI.put("/meeting", params);

// red flag
export const setRedFlag = (params) => ClientAPI.put(`/admin/red-flag`, params);

// notifications
export const getBellNotifications = (user_id, signal) =>
  API.post(`notification/${user_id}`, null, { signal });

export const updateBellNotifications = (user_id, signal) =>
  API.patch(`update-notification/${user_id}`, null, { signal });
// rating
export const getCandidateRatings = (id, type, sort, filter, controller) =>
  ClientAPI.get(
    `/admin/rating/${id}/${type}?sort_by=${sort}${filter !== null ? `&filter=${filter}` : ""
    }`,
    { signal: controller.signal }
  );

// client employees
export const getClientEmployees = (id, page, limit, search, controller) =>
  API.get(
    `/client/get-employees/${id}?offset=${page}&limit=${limit}&search=${search}`,
    { signal: controller.signal }
  );
export const getClientEmployeesList = (id, page, paginate, search, controller) =>
  API.get(`/hired/client/employees/${id}?page=${page}&paginate=${paginate}&search=${search}`,
    { signal: controller.signal }
  );

// lms
export const getLmsList = (page, limit, search, archive, controller) =>
  API.get(
    `/lms/list/${archive}?paginate=${limit}&page=${page}&search=${search}`,
    { signal: controller.signal }
  );

export const getLmsTypes = (controller) =>
  API.get("/lms/type", { signal: controller.signal });

export const getLmsClientList = (controller) =>
  API.get("/get-all-clients", { signal: controller.signal });

export const getLmsEmployeeList = (type, companyId, controller) =>
  API.get(
    type === "INTERNAL"
      ? `/employee/list/${type}`
      : `/employee/list/${type}/${companyId}`,
    { signal: controller.signal }
  );

export const deleteRestoreLms = (id, trigger) =>
  API.delete(`/lms/delete/${id}/${trigger}`);

export const saveLms = (formData) => API.post("/lms/store", formData);

export const getLmsDetails = (id, controller) =>
  API.get(`/lms/get/${id}`, { signal: controller.signal });
export const updateLms = (formData, id) =>
  API.put(`/lms/update/${id}`, formData);

// company profile
export const getCompanyProfile = (controller) =>
  API.get("/company-profile", { signal: controller.signal });
export const updateCompanyProfile = (formData) =>
  API.patch("/update-company-profile", formData);

// documents
export const deleteDocument = (query) => ClientAPI.delete(`/document/${query}`);
export const saveDocument = (params) => ClientAPI.post("/document", params);
export const saveProfilePic = (params) => ClientAPI.put("/user", params);
export const getApplicantCredentials = (query, controller) =>
  ClientAPI.get(`/user/${query}`, { signal: controller.signal });

// highlights
export const deleteHighlight = (id) => ClientAPI.delete(`/highlight/${id}`);

// referrals
export const getReferralList = (
  limit,
  page,
  search,
  origin,
  referrer,
  registrationFrom,
  registrationTo,
  employeeStatus,
  controller
) =>
  API.get(
    `/referrals/list?paginate=${limit}&page=${page}&search=${search}&origin${origin ? "[]" : ""
    }=${origin}&referrer${referrer ? "[]" : ""
    }=${referrer}&registration_from=${registrationFrom}&registration_to=${registrationTo}&employee_status${employeeStatus ? "[]" : ""
    }=${employeeStatus}`,
    {
      signal: controller.signal,
    }
  );
export const getReferralStatusList = (
  limit,
  page,
  search,
  referrer,
  status,
  amountFrom,
  amountTo,
  totalAmountFrom,
  totalAmountTo,
  countFrom,
  countTo,
  controller
) =>
  API.get(
    `/referrals/list/status?paginate=${limit}&page=${page}&search=${search}&referrer${referrer ? "[]" : ""
    }=${referrer}&amount_status${status ? "[]" : ""
    }=${status}&amount_from=${amountFrom}&amount_to=${amountTo}&total_amount_from=${totalAmountFrom}&total_amount_to=${totalAmountTo}&count_from=${countFrom}&count_to=${countTo}`,
    { signal: controller.signal }
  );
export const getReferralCandidateList = (
  limit,
  page,
  search,
  origin,
  referrer,
  registrationFrom,
  registrationTo,
  employeeStatus,
  controller
) =>
  API.get(
    `/referrals/list/candidate?paginate=${limit}&page=${page}&search=${search}&origin${origin ? "[]" : ""
    }=${origin}&referrer${referrer ? "[]" : ""
    }=${referrer}&registration_from=${registrationFrom}&registration_to=${registrationTo}&employee_status${employeeStatus ? "[]" : ""
    }=${employeeStatus}`,
    {
      signal: controller.signal,
    }
  );
export const getReferralHiredList = (
  limit,
  page,
  search,
  origin,
  referrer,
  registrationFrom,
  registrationTo,
  employeeStatus,
  controller
) =>
  API.get(
    `/referrals/list/hired?paginate=${limit}&page=${page}&search=${search}&origin${origin ? "[]" : ""
    }=${origin}&referrer${referrer ? "[]" : ""
    }=${referrer}&registration_from=${registrationFrom}&registration_to=${registrationTo}&employee_status${employeeStatus ? "[]" : ""
    }=${employeeStatus}`,
    {
      signal: controller.signal,
    }
  );
export const updateReferralAmount = (id, body) =>
  API.put(`/referrals/update/${id}`, body);
export const generateReferralStatusList = (
  dlType,
  limit,
  page,
  search,
  referrer,
  status,
  amountFrom,
  amountTo,
  totalAmountFrom,
  totalAmountTo,
  countFrom,
  countTo,
  controller
) =>
  API.get(
    `/referrals/export/status?type=${dlType}&paginate=${limit}&page=${page}&search=${search}&referrer${referrer ? "[]" : ""
    }=${referrer}&amount_status${status ? "[]" : ""
    }=${status}&amount_from=${amountFrom}&amount_to=${amountTo}&total_amount_from=${totalAmountFrom}&total_amount_to=${totalAmountTo}&count_from=${countFrom}&count_to=${countTo}`,
    { responseType: "blob" },
    { signal: controller.signal }
  );
export const generateReferralList = (
  dlType,
  limit,
  page,
  search,
  origin,
  referrer,
  registrationFrom,
  registrationTo,
  employeeStatus,
  controller
) =>
  API.get(
    `/referrals/export?type=${dlType}&paginate=${limit}&page=${page}&search=${search}&origin${origin ? "[]" : ""
    }=${origin}&referrer${referrer ? "[]" : ""
    }=${referrer}&registration_from=${registrationFrom}&registration_to=${registrationTo}&employee_status${employeeStatus ? "[]" : ""
    }=${employeeStatus}`,
    { responseType: "blob" },
    { signal: controller.signal }
  );
export const generateReferralCandidateList = (
  dlType,
  limit,
  page,
  search,
  origin,
  referrer,
  registrationFrom,
  registrationTo,
  employeeStatus,
  controller
) =>
  API.get(
    `/referrals/export/candidate?type=${dlType}&paginate=${limit}&page=${page}&search=${search}&origin${origin ? "[]" : ""
    }=${origin}&referrer${referrer ? "[]" : ""
    }=${referrer}&registration_from=${registrationFrom}&registration_to=${registrationTo}&employee_status${employeeStatus ? "[]" : ""
    }=${employeeStatus}`,
    { responseType: "blob" },
    { signal: controller.signal }
  );
export const generateReferralHiredList = (
  dlType,
  limit,
  page,
  search,
  origin,
  referrer,
  registrationFrom,
  registrationTo,
  employeeStatus,
  controller
) =>
  API.get(
    `/referrals/export/hired?type=${dlType}&paginate=${limit}&page=${page}&search=${search}&origin${origin ? "[]" : ""
    }=${origin}&referrer${referrer ? "[]" : ""
    }=${referrer}&registration_from=${registrationFrom}&registration_to=${registrationTo}&employee_status${employeeStatus ? "[]" : ""
    }=${employeeStatus}`,
    { responseType: "blob" },
    { signal: controller.signal }
  );

// English Proficiency Configuration
export const getEnglishAssessmentList = (query, controller) =>
  API.get(`/speaking-test/list/${query}`, {
    signal: controller?.signal,
  });
export const addEnglishAssessment = (params) =>
  API.post("/speaking-test/save", params);
export const updateEnglishAssessment = (id, params) =>
  API.put(`/speaking-test/update/${id}`, params);
export const updateEnglishAssessmentRetake = (id, params) =>
  API.put(`/speaking-test/config/${id}`, params);
export const deleteEnglishAssessmentSpeak = (id, type) =>
  API.delete(`/speaking-test/delete/speak/${id}/${type}`);
export const deleteEnglishAssessmentChoice = (id, type) =>
  API.delete(`/speaking-test/delete/choice/${id}/${type}`);

// interview request
export const getInterviewList = (limit, page, search, controller) =>
  API.get(
    `/interview/request/list?paginate=${limit}&page=${page}&search=${search}`,
    { signal: controller.signal }
  );
export const getTaUsers = (controller) =>
  API.get("/admin/users/get", { signal: controller.signal });
export const setInterviewer = (id, params) =>
  API.put(`/interview/request/update/${id}`, params);

// st rates
export const getStRateList = (country, archived, page, paginate, controller) =>
  API.get(
    `/salary/list/${country}/${archived}?paginate=${paginate}&page=${page}`,
    { signal: controller.signal }
  );
export const addUpdateStRate = (params) => API.post("/salary/save", params);
export const restoreDeleteStRate = (id, flag) =>
  API.delete(`/salary/delete/${id}/${flag}`);

// departments
export const getDepartmentList = (flag = 0, limit, page, search) =>
  ClientAPI.get(
    `admin/department/list/${flag}?paginate=${limit}&page=${page}&search=${search}`
  );
export const getDepartment = (id) =>
  ClientAPI.get(`admin/department/get/${id}`);

export const saveDepartment = (body) =>
  ClientAPI.post("admin/department", body);
export const deleteADepartment = (id, type) =>
  ClientAPI.delete(`admin/department/${id}/${type}`);
export const updateADepartment = (id, body) =>
  ClientAPI.put(`admin/department/${id}`, body);
export const deleteUserDepartment = (id) =>
  ClientAPI.delete(`admin/department/user/${id}`);
export const exportDepartmentList = (
  flagType,
  limit,
  page,
  search,
  dlType,
  controller
) =>
  ClientAPI.get(
    `/admin/department/export/${flagType}?paginate=${limit}&page=${page}&search=${search}&type=${dlType}`,
    { responseType: "blob" },
    { signal: controller.signal }
  );

// verify if phone number used for verification
export const checkPhoneIfVerified = (id, controller) =>
  ClientAPI.get(`phone/verified/${id}`, { signal: controller.signal });

// billing history overtime
export const getBillingHistoryOvertime = (
  page,
  paginate,
  filter,
  from,
  to,
  controller
) =>
  ClientAPI.get(
    `/admin/billing/overtime?page=${page}&paginate=${paginate}&filter=${filter}&from=${from}&to=${to}`,
    {
      signal: controller.signal,
    }
  );
export const uploadOvertime = (params) =>
  ClientAPI.post("admin/request/overtime", params);
export const getOvertimeDetails = (id, page, search) =>
  ClientAPI.get(
    `admin/billing/overtime/${id}?page=${page}&paginate=10&search_input=${search}`
  );

// billing history undertime
export const getBillingHistoryUndertime = (
  page,
  paginate,
  filter,
  from,
  to,
  controller
) =>
  ClientAPI.get(
    `/admin/billing/undertime/list?page=${page}&paginate=${paginate}&filter=${filter}&from=${from}&to=${to}`,
    {
      signal: controller.signal,
    }
  );
export const uploadUndertime = (params) =>
  ClientAPI.post("admin/request/undertime", params);
export const getUndertimeDetails = (page, search, date) =>
  ClientAPI.get(
    `admin/billing/undertime/get?page=${page}&paginate=10&search_input=${search}&date=${date}`
  );

// billing history lowp
export const getBillingHistoryLWOP = (page, paginate, controller) =>
  ClientAPI.get(`/admin/billing/leave?page=${page}&paginate=${paginate}`, {
    signal: controller.signal,
  });
export const uploadLWOP = (params) =>
  ClientAPI.post("admin/request/leave", params);
export const getLWOPDetails = (id, page) =>
  ClientAPI.get(`admin/billing/leave/${id}?page=${page}&paginate=10`);

// billing history list
export const getBillingHistoryList = (
  page,
  paginate,
  search,
  status,
  controller
) =>
  ClientAPI.get(
    `/admin/billing/history/list/${status}?page=${page}&paginate=${paginate}&search_input=${search}`,
    { signal: controller.signal }
  );
export const exportBillingHistory = (status, type) =>
  ClientAPI.get(`/admin/billing/history/list/export/${status}?type=${type}`, {
    responseType: "blob",
  });

// billing history hourly rate
export const getBillingHistoryHourlyRate = (page, paginate, search, controller) =>
  ClientAPI.get(`/admin/billing/hourly-rate?page=${page}&paginate=${paginate}&search_input=${search}`, {
    signal: controller.signal,
  });
export const saveTotalHours = (params) =>
  ClientAPI.post("admin/billing/hourly-rate", params);
export const exportHourlyRate = (type) =>
  ClientAPI.get(`/admin/billing/hourly-rate/export?type=${type}`, {
    responseType: "blob",
  });

// hr settings > manage user access
export const getManageUserAccessList = (controller) =>
  API.get(`/restricted/user/list`, {
    signal: controller.signal,
  });
export const getEmployees = (type, controller) =>
  API.get(`/employee/list/${type}`, { signal: controller.signal });

export const saveManageUserAccess = (params) =>
  API.post("/restricted/user/save", params);

// invoice
export const adjustInvoice = (id, params) =>
  ClientAPI.put(`/admin/billing/adjustment/${id}`, params);
export const downloadInvoice = (id) =>
  ClientAPI.get(`/admin/invoice/download/${id}`, { responseType: "blob" });
export const voidInvoice = (id) => ClientAPI.put(`/admin/billing/void/${id}`);
export const deleteInvoice = (id, params) =>
  ClientAPI.delete(`/admin/billing/delete/${id}${params}`);
export const sendInvoiceToClient = (id) =>
  ClientAPI.post(`/admin/invoice/send/${id}`);
export const sendBulkInvoice = (params) =>
  ClientAPI.post(`/admin/invoice/send`, params);
export const editInvoice = (id, params) =>
  ClientAPI.put(`/admin/billing/edit/${id}`, params);
export const createInvoice = (params) =>
  ClientAPI.post("/admin/billing/add", params);

// client profile sow
export const getClientProfileSow = (query, controller) =>
  ClientAPI.get(`/client/sow/list/${query}}`, { signal: controller.signal });

// uploap onboard document and update status
export const uploadOnboardDocument = (query, body) =>
  ClientAPI.put(`/admin/upload/job-offer/${query}`, body);

// asset gennerate billing
export const assetGenerateBilling = (query) =>
  ClientAPI.post(`/admin/billing/asset/${query}`);
export const assetGenerateShippingBilling = (query) =>
  ClientAPI.post(`/admin/billing/asset/${query}`);

// Choose SOW Contract
export const getUploadedSOWList = (sow_id) =>
  API.get(`/get/sow/${sow_id}`);
export const uploadSOWContract = (type, params) =>
  API.post(`/upload/sow/${type}`, params);
export const sendSOWContract = (sow_file_id) =>
  API.put(`/send/sow/${sow_file_id}`);

//company information
export const saveClientProfile = (params) =>
  ClientAPI.post("/admin/profile", params);
export const getIndustry = (controller) =>
  ClientAPI.get("/configuration/industry", { signal: controller.signal });

//clearance
export const getClearanceList = (
  page,
  limit,
  keyword,
  controller
) =>
  API.get(
    `/clearance/approver/list?page=${page}&paginate=${limit}&search=${keyword}`,
    { signal: controller?.signal }
  );
export const saveApproverClearance = (params) =>
  API.post("/clearance", params);
export const getClearance = (id, controller) =>
  API.get(`/clearance/${id}`, { signal: controller.signal });
export const getClearanceForm = (id, controller) =>
  API.get(`/clearance/approver/get/${id}`, { signal: controller.signal });
export const updateApproverClearance = (id, params) =>
  API.put(`/clearance/approver/update/${id}`, params);
export const deleteClearanceAccountability = (id) =>
  API.delete(`/clearance/accountability/${id}`);

// leave plan
export const getLeavePlanList = (type, params, controller) =>
  API.get(params ? `/leave/plan/list/${type}?${params}` : `/leave/plan/list/${type}`, { signal: controller.signal });
export const getLeavePlanConfig = (id) =>
  API.get(`/leave/plan/get/${id}`);
export const addLeavePlan = (params) =>
  API.post("/leave/plan/save", params);
export const updateLeavePlan = (id, params) =>
  API.put(`/leave/plan/update/${id}`, params);
export const deleteLeavePlan = (id, type) =>
  API.delete(`/leave/plan/delete/${id}/${type}`);

// leave type config
export const getLeaveTypeList = (type, params, controller) =>
  API.get(`/leave/configuration/list/${type}?${params}`, { signal: controller.signal });
export const getLeaveTypeConfig = (id) =>
  API.get(`/leave/configuration/get/${id}`);
export const addLeaveType = (params) =>
  API.post("/leave/configuration/save", params);
export const updateLeaveType = (id, params) =>
  API.put(`/leave/configuration/update/${id}`, params);
export const deleteLeaveType = (id, type) =>
  API.delete(`/leave/configuration/delete/${id}/${type}`);

// policy config
export const getPolicyList = (type, params, controller) =>
  API.get(`/leave/policy/list/${type}?${params}`, { signal: controller.signal });
export const getPolicyConfig = (id) =>
  API.get(`/leave/policy/get/${id}`);
export const addPolicy = (params) =>
  API.post("/leave/policy/save", params);
export const updatePolicy = (id, params) =>
  API.put(`/leave/policy/update/${id}`, params);
export const deletePolicy = (id, type) =>
  API.delete(`/leave/policy/delete/${id}/${type}`);

// holiday setting
export const getHolidayCountryList = (type, params, controller) =>
  API.get(params ? `/holiday/country/list/${type}?${params}` : `/holiday/country/list/${type}`, { signal: controller.signal });
export const getHolidayCountryConfig = (id, archive) =>
  API.get(`/holiday/date/list/${id}/${archive}`);
export const addHolidayCountry = (params) =>
  API.post("/holiday/country/save", params);
export const updateHolidayCountry = (id, params) =>
  API.put(`/holiday/country/update/${id}`, params);
export const deleteHolidayCountry = (id, type) =>
  API.delete(`/holiday/country/delete/${id}/${type}`);

// holiday type
export const getHolidayTypeList = (type, params, controller) =>
  API.get(params ? `/holiday/type/list/${type}?${params}` : `/holiday/type/list/${type}`, { signal: controller.signal });
export const getHolidayType = (id) =>
  API.get(`/holiday/type/get/${id}`);
export const addHolidayType = (params) =>
  API.post("/holiday/type/save", params);
export const updateHolidayType = (id, params) =>
  API.put(`/holiday/type/update/${id}`, params);
export const deleteHolidayType = (id, type) =>
  API.delete(`/holiday/type/delete/${id}/${type}`);

export const getHolidayDateList = (id, type, params, controller) =>
  API.get(`/holiday/date/list/${id}/${type}?${params}`, { signal: controller.signal });
export const getHolidayDateConfig = (id) =>
  API.get(`/holiday/date/get/${id}`);
export const addHolidayDate = (params) =>
  API.post("/holiday/date/save", params);
export const updateHolidayDate = (id, params) =>
  API.put(`/holiday/date/update/${id}`, params);
export const deleteHolidayDate = (id, type) =>
  API.delete(`/holiday/date/delete/${id}/${type}`);
export const emailToEmployees = (params) =>
  API.post(`/holiday/date/send`, params);

// dtr
export const getDailyTimeRecord = (params, controller) =>
  ClientAPI.get("/work-schedule", { params }, { signal: controller?.signal });
export const saveWorkSchedule = (params) =>
  ClientAPI.post("/work-schedule", params);
export const getTimeLogs = (params, controller) =>
  ClientAPI.get("/time/logs", { params }, { signal: controller?.signal });
export const getScheduleList = (query, controller) =>
  API.get(`/work-schedule-scheme/list/${query}`, { signal: controller?.signal });
export const getScheduleData = (query, controller) =>
  API.get(`/work-schedule-scheme/get/${query}`, { signal: controller?.signal });
export const updateScheduleData = (id, params) =>
  ClientAPI.put(`/work-schedule/${id}`, params);
export const getTimeRecordList = (query, params, controller) =>
  API.get(`/time-record/summary/${query}`, { params, signal: controller?.signal });
export const downloadTimeRecordList = (query, params) =>
  API.get(
    `/time-record/export/${query}`,
    { params, responseType: "blob" }
  );
export const getWorkScheduleList = (query, params, controller) =>
  API.get(`/work-schedule/list/${query}`, { params, signal: controller?.signal });
export const downloadWorkScheduleList = (query, params) =>
  API.get(
    `/work-schedule-scheme/export/${query}`,
    { params, responseType: "blob" }
  );

// request (bonus, commission, salary)
export const getRequestList = (query, controller) =>
  ClientAPI.get(`/client/request/salary${query}`, { signal: controller?.signal });
export const updateRequestList = (query, params) =>
  ClientAPI.put(query ? `/client/request/salary/${query}` : "/client/request/salary", params);
export const searchEmployee = (query, controller) =>
  ClientAPI.get(`/search/users${query}`, { signal: controller?.signal });
export const requestIncrease = (params) =>
  ClientAPI.post(`/client/request/salary`, params);
export const checkProratedAdjustment = (query, controller) =>
  ClientAPI.get(`/pro-rated/salary/adjustment${query}`, { signal: controller?.signal });

// billing settings
export const getBillingSettingRulesList = (controller) =>
  API.get(`/billing/rules`, { signal: controller?.signal });
export const saveBillingSettings = (formData) =>
  API.post("/billing/rules", formData);
export const getBillingSettingRules = (id, controller) =>
  API.get(`/billing/rules/${id}`, { signal: controller?.signal });

// Employee leave credits
export const getLeaveCreditList = (params, controller) =>
  API.get(`/leave/credit/list?${params}`, { signal: controller.signal });
export const addLeaveCreditType = (params) =>
  API.post(`/leave/credit/save`, params);
export const updateLeaveCredit = (id, params) =>
  API.put(`/leave/credit/update/${id}`, params);
export const userList = (controller) =>
  API.get(`/employees/hris`, { signal: controller.signal });
export const getLeaveCredit = (id) =>
  API.get(`/leave/credit/get/${id}`);

// filed request
export const getFiledRequestList = (type, status, params, controller) =>
  API.get(`/form/approval/list/${type}/${status}?${params}`, { signal: controller.signal });
export const updateFiledRequest = (id, params) =>
  API.put(`/form/approval/update/${id}`, params);
export const getFiledRequestApprover = (id, controller) =>
  API.get(`/form/approver/get/${id}`, { signal: controller.signal });
export const saveFiledRequestApprover = (params) =>
  API.post("/form/approver/save", params);
export const updateFiledRequestApprover = (params) =>
  API.put("/form/approver/update", params);
export const getApprovers = (id, controller) =>
  API.get(`/form/approval/get/${id}`, { signal: controller.signal });

// hr reports
export const getHrReportsList = (country, controller) =>
  API.get(`/report/list/${country}`, { signal: controller.signal });
export const generateHrReport = (id, params) =>
  API.get(`/report/generate/${id}`, { responseType: "blob", params: params });
export const getEmployeeFilter = (type, client_id, country) =>
  API.get(client_id ? `/report/filters/${type}/${client_id}?country=${country}` : `/report/filters/${type}?country=${country}`);

// commission management > st personnel
export const getStPersonnelList = (params, controller) =>
  API.get(`/commission/internal-users/get?${params}`, { signal: controller.signal });

// commission management > commission > dashboard
export const getCommissionDashboard = (params, controller) =>
  API.get(`/commission/dashboard/${params}`, { signal: controller.signal });

// commission management > commission > clients
export const getCommissionClientsList = (params, controller) =>
  API.get(`/commission/client/list/${params}`, { signal: controller.signal });

// commission management > commission > details
export const getCommissionDetailsList = (params, controller) =>
  API.get(`/commission/detail/list/${params}`, { signal: controller.signal });
export const saveCommissionDetailsCost = (params) =>
  API.post("/commission/detail/company-cost", params);

// commission management > commission > sheme
export const getCommissionClients = (id) =>
  API.get(`/commission/clients/get/${id}`);
export const saveCommissionScheme = (params) =>
  API.post("/commission/config/save", params);
export const updateCommissionScheme = (id, params) =>
  API.put(`/commission/config/update/${id}`, params);
export const getCommissionAgents = (id) =>
  API.get(`/commission/agent/get/${id}`);
export const saveCommissionSchemeSales = (params) =>
  API.post("/commission/agent/save", params);
export const getCommissionSchemeData = (params, controller) =>
  API.get(`/commission/config/get/${params}`, { signal: controller.signal });
export const getCommissionSchemeSalesData = (params, controller) =>
  API.get(`/commission/agent/get/${params}`, { signal: controller.signal });

// commission management > client assignment
export const getCommissionClientAssignmentList = (params, controller) =>
  API.get(`/commission/client/assignment?${params}`, { signal: controller.signal });
export const saveClientAssignment = (params) =>
  API.post("/commission/client/assigning", params);

// commission management > employees
export const getCommissionEmployeesList = (params, controller) =>
  API.get(`/commission/employee/list?${params}`, { signal: controller.signal });
export const saveCommissionEmployees = (params) =>
  API.post("/commission/employee/save", params);
export const saveCommissionPayment = (params) =>
  API.post("/commission/detail/payment", params);

// tasks
export const getMyTaskList = (params, controller) =>
  API.get(`/admin/task/list${params}`, { signal: controller.signal });
export const getTasksList = (params, controller) =>
  API.get(`/task/list${params}`, { signal: controller.signal });
export const getTask = (id) =>
  API.get(`/task/get/${id}`);
export const saveTask = (params) =>
  API.post(`/task/save`, params);
export const updateTask = (id, params) =>
  API.put(`/task/update/${id}`, params);
export const getMyCreatedTaskList = (params, controller) =>
  API.get(`/task/list${params}`, { signal: controller.signal });

// asset approver
export const getAssetApprover = (controller) =>
  API.get(`/assets/approver`, { signal: controller.signal });
export const saveAssetApprover = (params) =>
  API.post(`/assets/approver`, params);

// timesheet lock
export const getTimeLockList = (params, controller) =>
  API.get(`/form/lock/list?${params}`, { signal: controller.signal });
export const getTimeLockConfig = (id) =>
  API.get(`/form/lock/get/${id}`);
export const addTimeLock = (params) =>
  API.post("/form/lock/save", params);
export const updateTimeLock = (id, params) =>
  API.put(`/form/lock/update/${id}`, params);
export const updateTimeLockStatus = (id, status) =>
  API.put(`/form/lock/change-status/${id}/${status}`);
export const deleteTimeLockConfig = (id) =>
  API.delete(`/form/lock/delete/${id}`);

// asset inventory
export const getAssetList = (params, controller) =>
  API.get(`/asset/inventory/list/${params}`, { signal: controller.signal });
export const addAssetInventory = (params) =>
  API.post("/asset/inventory/save", params);
export const updateAssetInventory = (id, params) =>
  API.put(`/asset/inventory/update/${id}`, params);
export const getAssetConfig = (id) =>
  API.get(`/asset/inventory/get/${id}`);
export const deleteAssetConfig = (id, type) =>
  API.delete(`/asset/inventory/delete/${id}/${type}`);

// approver
export const getReimbursementApprover = (id) =>
  API.get(`/reimbursement/approver/get/${id}`);
export const saveReimbursementApprover = (params) =>
  API.post("/reimbursement/approver/save", params);
export const updateReimbursementApprover = (params) =>
  API.put("/reimbursement/approver/update", params);
export const getUsedAssetConfig = (id) =>
  API.get(`/asset/get/${id}`);

// reimbursement
export const getReimbursementList = (params, controller) =>
  API.get(`/reimbursement/list${params}`, { signal: controller.signal });
export const getReimbursementConfig = (id) =>
  API.get(`/reimbursement/get/${id}`);
export const updateReimbursement = (id, params) =>
  API.put(`/reimbursement/update/${id}`, params);