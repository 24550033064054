import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { removeFromLocalStorage } from "../../utils/localStorage";

const RedirectComponent = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    removeFromLocalStorage("st-admin-credentials");
  };

  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div style={{ display: "flex", alignItems: "center", minHeight: "100vh" }}>
      <div className="" style={{ width: "100%", textAlign: "center" }}>
        <p style={{ fontSize: "32px" }}>Redirecting...</p>
        <p style={{ fontSize: "16px", marginTop: "-35px" }}>
          Please click&nbsp;
          <span
            style={{ color: "#1890ff", cursor: "pointer" }}
            onClick={handleLogout}
          >
            here&nbsp;
          </span>
          if you are not redirected within a few seconds
        </p>
      </div>
    </div>
  );
};

export default RedirectComponent;
