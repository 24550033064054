import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Stack from "@mui/material/Stack";

import { useAuth } from "../../utils/useAuth";
import MenuLayout from "../layout/MenuLayout";

import stLoader from "../../assets/st-loader.svg";

const ProtectedRoutes = ({ isSplash }) => {
  const user = useAuth();

  // || user.new === 1

  if ((!user || user.new === 1) && !isSplash) return <Navigate to="/login" />;

  return isSplash ? (
    <div
      style={{
        width: "100%",
        height: "100vh",
        background: "#fff",
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "100%",
        }}
      >
        <img
          src={stLoader}
          alt="Loading..."
          style={{
            width: "50%",
            height: "50%",
          }}
        />
      </Stack>
    </div>
  ) : (
    <MenuLayout>
      <Outlet />
    </MenuLayout>
  );
};

export default ProtectedRoutes;
