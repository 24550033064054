import React, { useState, useEffect } from "react";
import Flag from "react-flagkit";

import {
  Menu,
  IconButton,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";

// local imports
import { useDataContext } from "../../../context/DataContext";

const dummyData = [
  { abr: "PH", name: "Philippines", language: "tl" },
  { abr: "US", name: "United States", language: "en" },
  { abr: "CA", name: "Canada", language: "en" },
  { abr: "MX", name: "Mexico", language: "es" },
  { abr: "PL", name: "Poland", language: "pl" },
  { abr: "VN", name: "Vietnam", language: "vi" },
  { abr: "HK", name: "Hong Kong", language: "zh-CN" },
  { abr: "IN", name: "India", language: "hi" },
  { abr: "CO", name: "Colombia", language: "es" },
  { abr: "CN", name: "China", language: "zh-CN" },
  { abr: "PR", name: "Puerto Rico", language: "es" },
  { abr: "EG", name: "Egypt", language: "ar" },

];

const Flags = () => {
  // const [currentFlag, setCurrentFlag] = useState("PH");
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentFlag, setCurrentFlag } = useDataContext();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectCountry = (abr, language) => {
    setCurrentFlag(abr);
    setAnchorEl(null);

    var selectField = document.querySelector("#google_translate_element select");
    for (var i = 0; i < selectField.children.length; i++) {
      var option = selectField.children[i];
      // find desired langauge and change the former language of the hidden selection-field 
      if (option.value == language) {
        selectField.selectedIndex = i;
        // trigger change event afterwards to make google-lib translate this side
        selectField.dispatchEvent(new Event('change'));
        break;
      }
    }
  };

  useEffect(() => {
    document.cookie = "googtrans=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  })

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Flag country={currentFlag} size={25} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            padding: 0,
            borderRadius: 1,
          },
        }}
        PaperProps={{
          elevation: 0,
          padding: 0,
          borderradius: 1,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {dummyData.map((item) => (
          <MenuItem
            key={item.abr}
            onClick={() => handleSelectCountry(item.abr, item.language)}
          >
            <ListItemIcon>
              <Flag country={item.abr} size={25} />
            </ListItemIcon>
            <Typography variant="body2">{item.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default Flags;
