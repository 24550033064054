import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import "./_notification.scss";
// material ui import
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { getBellNotifications, updateBellNotifications } from "../../../api";
import { getUser, setToSessionStorage } from "../../../utils/localStorage";
import { onMessageListener } from "../../../config/firebase";
import { id } from "date-fns/locale";

// local import
// import { onMessageListener } from "../../../config/firebase";

const BellNotification = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState(null);
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(false);
  const [ClassNames, setClassName] = useState(false);
  const open = Boolean(anchorEl);
  const [datas, setData] = useState([]);
  const user = getUser();
  const { data, isError, error } = useQuery(
    "notifications",
    async ({ signal }) => {
      try {
        const res = await getBellNotifications(user?.user_id, signal);
        return {
          notifications: res.data.data,
          active_count: res.data.count,
        };
      } catch (err) {
        return err;
      }
    }
  );

  const handleOpen = (event) => {
    setClassName("arrow");
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(false);
    setClassName("closeArrow");
  };

  function navigateToLink(link, title) {
    if (link.includes("https")) {
      if (title.toLowerCase().includes("create a meeting schedule")) {
        setToSessionStorage("isToClientRequestInterview", JSON.stringify(true));
      }
      window.location.replace(link);
    } else if (
      link.includes("client-staging.satelliteteams.com") ||
      link.includes("client.satelliteteams.com")
    ) {
      window.location.replace(link);
    } else {
      navigate(link);
    }
  }

  const handleUpdate = (id, visit) => {
    updateBellNotifications(id)
      .then(async (res) => {
        try {
          setNotifications({
            notifications: notifications.notifications,
            active_count:
              visit !== 0
                ? notifications.active_count - 0
                : notifications.active_count != 0
                ? notifications.active_count - 1
                : 0,
          });
          queryClient.fetchQuery(["notifications"]);
          setAnchorEl(false);
          setClassName("closeArrow");
          const ress = await getBellNotifications(user?.user_id, null);
        } catch (err) {
          setAnchorEl(false);
          setClassName("closeArrow");
          return err;
        }
      })
      .catch((err) => {
        setAnchorEl(false);
        setClassName("closeArrow");
        return err;
      });
  };
  onMessageListener()
    .then((payload) => {
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    if (data) setNotifications(data);
    return () => queryClient.cancelQueries(["notifications"]);
  }, [data]);
  return (
    <>
      <IconButton onClick={handleOpen}>
        <Badge badgeContent={notifications?.active_count} color="error">
          <NotificationsNoneIcon
            sx={{
              fontSize: 25,
              color: "common.white",
            }}
          />
        </Badge>
      </IconButton>
      {notifications?.notifications?.length > 0 && (
        <>
          <div className={open ? "arrow" : "closeArrow"} />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiPaper-root": {
                padding: 0,
                borderRadius: 1,
                height: "50%",
                overflowY: "scroll",
              },
            }}
            PaperProps={{
              elevation: 0,
              padding: 0,
              borderradius: 1,
              sx: {
                overflow: "unset",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <>
              {notifications.notifications.map((item) => {
                return (
                  <MenuItem
                    key={item.id}
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor:
                          item.visit === 0 ? "blue.900" : "brandColor.900",
                        padding: 1,
                        borderRadius: "5px",
                        width: 320,
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontSize: ".85rem",
                          color: "brandColor.500",
                          marginBottom: "5px",
                          fontWeight: 500,
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "0.75rem",
                          color: "#000",
                          whiteSpace: "break-spaces",
                          marginBottom: "5px",
                        }}
                      >
                        {item.highlight}
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "0.8rem",
                          color: "brandColor.400",
                          fontWeight: 600,
                        }}
                        onClick={() => {
                          navigateToLink(item.link, item.title);
                          handleUpdate(item.id, item.visit);
                          // handleClose();
                          setAnchorEl(false);
                          setClassName("closeArrow");
                        }}
                      >
                        Click here
                      </Typography>
                    </Box>
                  </MenuItem>
                );
              })}
            </>
          </Menu>
        </>
      )}
    </>
  );
};

export default BellNotification;
