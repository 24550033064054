import React, { useState, useEffect, useContext } from "react";

import { generateFirebaseToken } from "../config/firebase";

import { getCompanyProfile } from "../api";

export const DataContext = React.createContext({});

export const DataContextProvider = ({ children }) => {
  const [companyProfile, setCompanyProfile] = useState(null);
  const [currentFlag, setCurrentFlag] = useState(window.location.href.includes("commandmx") ? "MX" : "PH");
  const [tokenNotification, setTokenNotification] = useState(null);

  generateFirebaseToken(setTokenNotification);

  useEffect(() => {
    const controller = new AbortController();

    getCompanyProfile(controller)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setCompanyProfile(res.data[0]);
          // const favicon = document.getElementById("my-favicon");
          // favicon.setAttribute("href", res.data.data.favicon);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <DataContext.Provider
      value={{
        companyProfile,
        currentFlag,
        setCurrentFlag,
        tokenNotification,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => {
  return useContext(DataContext);
};
