export const getUser = () => {
  const user = localStorage.getItem("st-admin-credentials");

  if (user) return JSON.parse(user);

  return null;
};

export const otherToken = () => {
  const token = localStorage.getItem("st-admin-other-token");

  if (token) return token;

  return null;
};

export const removeFromLocalStorage = (key) => localStorage.removeItem(key);
export const removeFromSessionStorage = (key) => sessionStorage.removeItem(key);

export const setToLocalStorage = (key, value) => {
  if (key === "st-admin-credentials") {
    localStorage.setItem(key, JSON.stringify(value));
    return;
  }

  localStorage.setItem(key, value);
};

export const setToSessionStorage = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const getFromSessionStorage = (key) => {
  const val = sessionStorage.getItem(key);

  if (val) return val;

  return null;
};

export const getFromLocalStorage = (key) => {
  const val = localStorage.getItem(key);

  if (val) return val;

  return null;
};

export const getToken = () => {
  const token = localStorage.getItem("st-admin-credentials");

  if (token) return JSON.parse(token);

  return null;
};
