import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { QueryClientProvider } from "react-query";
import "react-medium-image-zoom/dist/styles.css";
import "react-datepicker/dist/react-datepicker.css";
// local imports
import App from "./App";
import store from "./redux/store";
import { theme } from "./utils/theme";
import { queryClient } from "./config/queryClient";
import "./scss/_resets.scss";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

serviceWorkerRegistration.register();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          maxSnack={3}
          autoHideDuration={4000}
        >
          <BrowserRouter>
            <CssBaseline />
            <App />
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </Provider>
  // </React.StrictMode>
);