import React from "react";
import { Outlet } from "react-router-dom";
import Stack from "@mui/material/Stack";

import RedirectComponent from "../general/RedirectComponent";
import NoMenuLayout from "../layout/NoMenuLayout";
import { useAuth } from "../../utils/useAuth";

import stLoader from "../../assets/st-loader.svg";

const PublicRoutes = ({
  hasLayout = true,
  hasBgName = false,
  notFound = false,
  isSplash,
}) => {
  const user = useAuth();

  // && user?.new !== 1

  if (user && !notFound && user?.new !== 1 && !isSplash) {
    return <RedirectComponent />;
  }

  // if (user) return <Navigate to="/redirect" />;

  return isSplash ? (
    <div
      style={{
        width: "100%",
        height: "100vh",
        background: "#fff",
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "100%",
        }}
      >
        <img
          src={stLoader}
          alt="Loading..."
          style={{
            width: "50%",
            height: "50%",
          }}
        />
      </Stack>
    </div>
  ) : hasLayout ? (
    <NoMenuLayout hasBgName={hasBgName}>
      <Outlet />
    </NoMenuLayout>
  ) : (
    <Outlet />
  );
};

export default PublicRoutes;
