import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Loadable from "react-loadable";
import { SnackbarProvider } from "notistack";
import { useSelector } from "react-redux";

// local imports
import PublicRoutes from "./components/routes/PublicRoutes";
import ProtectedRoutes from "./components/routes/ProtectedRoutes";
import LoadingComponent from "./components/general/LoadingComponent";
import { replaceAndSpace, data } from "./utils/common";
import { DebugMode } from "./utils/DebugMode";
import { DataContextProvider } from "./context/DataContext";
import { getUser } from "./utils/localStorage";

import "./app.css";

const Login = Loadable({
  loader: () => import("./pages/auth/Login"),
  loading: LoadingComponent,
});

const ForgotPassword = Loadable({
  loader: () => import("./pages/auth/ForgotPassword"),
  loading: LoadingComponent,
});

const ResetPassword = Loadable({
  loader: () => import("./pages/auth/ResetPassword"),
  loading: LoadingComponent,
});

const Dashboard = Loadable({
  loader: () => import("./pages/dashboard/Dashboard"),
  loading: LoadingComponent,
});

const AccountUser = Loadable({
  loader: () => import("./pages/account/UsersPageContainer"),
  loading: LoadingComponent,
});

const AccountRolesAndPermissions = Loadable({
  loader: () => import("./pages/account/RolesAndPermissions"),
  loading: LoadingComponent,
});

const ManagementAssetManagementListInternal = Loadable({
  loader: () => import("./pages/management/asset/AssetManagementListInternal"),
  loading: LoadingComponent,
});

const ManagementAssetManagementListExternal = Loadable({
  loader: () => import("./pages/management/asset/AssetManagementListExternal"),
  loading: LoadingComponent,
});

const ManagementAssetInventory = Loadable({
  loader: () => import("./pages/management/asset/AssetInventory"),
  loading: LoadingComponent,
});

const ManagementAssetManagementRequestInternal = Loadable({
  loader: () => import("./pages/management/asset/RequestInternal"),
  loading: LoadingComponent,
});

const ManagementAssetManagementRequestExternal = Loadable({
  loader: () => import("./pages/management/asset/RequestExternal"),
  loading: LoadingComponent,
});

const ManagementSettings = Loadable({
  loader: () => import("./pages/management/asset/Settings"),
  loading: LoadingComponent,
});

const CandidateManagement = Loadable({
  loader: () => import("./pages/management/candidate/CandidateManagement"),
  loading: LoadingComponent,
});

const AdvanceSearch = Loadable({
  loader: () => import("./pages/management/candidate/AdvanceSearch"),
  loading: LoadingComponent,
});

const HRManagement = Loadable({
  loader: () => import("./pages/management/hr/HRManagement"),
  loading: LoadingComponent,
});

const Hired = Loadable({
  loader: () => import("./pages/management/hr/Hired"),
  loading: LoadingComponent,
});

const Reports = Loadable({
  loader: () => import("./pages/management/hr/Reports"),
  loading: LoadingComponent,
});

const EditHiredInformation = Loadable({
  loader: () => import("./pages/management/hr/EditHiredInformation"),
  loading: LoadingComponent,
});

const PreviousEmployeeList = Loadable({
  loader: () => import("./pages/management/hr/PreviousEmployeeList"),
  loading: LoadingComponent,
});

const ViewResumeCandidate = Loadable({
  loader: () => import("./pages/management/hr/ViewResumeCandidate"),
  loading: LoadingComponent,
});

const NewJobOffer = Loadable({
  loader: () => import("./pages/management/hr/NewJobOffer"),
  loading: LoadingComponent,
});

const ClientManagement = Loadable({
  loader: () => import("./pages/management/client/ClientManagement"),
  loading: LoadingComponent,
});

const AddClient = Loadable({
  loader: () => import("./pages/management/client/AddClient"),
  loading: LoadingComponent,
});

const CompensationList = Loadable({
  loader: () => import("./pages/management/client/CompensationList"),
  loading: LoadingComponent,
});

const NotFound = Loadable({
  loader: () => import("./pages/notFound/NotFound"),
  loading: LoadingComponent,
});

const ComingSoon = Loadable({
  loader: () => import("./pages/comingSoon/ComingSoon"),
  loading: LoadingComponent,
});

const NewCandidate = Loadable({
  loader: () => import("./pages/management/candidate/NewCandidate"),
  loading: LoadingComponent,
});

const ViewCandidate = Loadable({
  loader: () => import("./pages/management/candidate/ViewCandidate"),
  loading: LoadingComponent,
});

const ClientSchedules = Loadable({
  loader: () => import("./pages/management/client/ClientSchedules"),
  loading: LoadingComponent,
});

const ClientProfile = Loadable({
  loader: () => import("./pages/management/client/ClientProfile"),
  loading: LoadingComponent,
});

const CompanyProfile = Loadable({
  loader: () => import("./pages/account/CompanyProfile"),
  loading: LoadingComponent,
});

const SystemConfiguration = Loadable({
  loader: () => import("./pages/systemConfiguration/SystemConfiguration"),
  loading: LoadingComponent,
});

const EventsCalendar = Loadable({
  loader: () => import("./pages/events/EventsCalendar"),
  loading: LoadingComponent,
});

const RedirectGoogleCalendar = Loadable({
  loader: () => import("./components/general/RedirectGoogleCalendar"),
  loading: LoadingComponent,
});

const HRSettings = Loadable({
  loader: () => import("./pages/management/hr/Settings"),
  loading: LoadingComponent,
});

const FiledRequest = Loadable({
  loader: () => import("./components/hrManagement/timeKeeping/filedRequest/FiledRequest"),
  loading: LoadingComponent,
});

const TimeRecord = Loadable({
  loader: () => import("./components/hrManagement/timeKeeping/timeRecord/TimeRecord"),
  loading: LoadingComponent,
})

const WorkSchedule = Loadable({
  loader: () => import("./components/hrManagement/timeKeeping/workSchedule/WorkSchedule"),
  loading: LoadingComponent,
});

const Billing = Loadable({
  loader: () => import("./pages/billingInvoices/Billing"),
  loading: LoadingComponent,
});

const BillingHistory = Loadable({
  loader: () => import("./pages/billingInvoices/BillingHistory"),
  loading: LoadingComponent,
});

const Invoices = Loadable({
  loader: () => import("./pages/billingInvoices/Invoices"),
  loading: LoadingComponent,
});

const PaymentFacility = Loadable({
  loader: () => import("./pages/billingInvoices/PaymentFacility"),
  loading: LoadingComponent,
});

const Zoom = Loadable({
  loader: () => import("./components/zoom/Zoom"),
  loading: LoadingComponent,
});
const MyStatistics = Loadable({
  loader: () => import("./pages/myStatistics/MyStatistics"),
  loading: LoadingComponent,
});
const MyTaskList = Loadable({
  loader: () => import("./pages/myTaskList/MyTaskList"),
  loading: LoadingComponent,
});
const LearningDevelopment = Loadable({
  loader: () =>
    import("./pages/management/employeeRelations/LearningDevelopment"),
  loading: LoadingComponent,
});
const Referrals = Loadable({
  loader: () => import("./pages/referral/Referrals"),
  loading: LoadingComponent,
});
const EmployeeLeaveCredits = Loadable({
  loader: () => import("./pages/management/hr/EmployeeLeaveCredits"),
  loading: LoadingComponent,
});

const STPersonnel = Loadable({
  loader: () => import("./pages/management/commission/STPersonnel"),
  loading: LoadingComponent,
});

const Commission = Loadable({
  loader: () => import("./pages/management/commission/Commission"),
  loading: LoadingComponent,
});

const TransactionHistory = Loadable({
  loader: () => import("./pages/management/commission/TransactionHistory"),
  loading: LoadingComponent,
});

const Clients = Loadable({
  loader: () => import("./pages/management/commission/Clients"),
  loading: LoadingComponent,
});

const MyStatisticSales = Loadable({
  loader: () => import("./pages/management/commission/MyStatisticSales"),
  loading: LoadingComponent,
});

const Employees = Loadable({
  loader: () => import("./pages/management/commission/Employees"),
  loading: LoadingComponent,
});

const RequestInterview = Loadable({
  loader: () => import("./pages/management/hr/RequestInterview"),
  loading: LoadingComponent,
});

const Clearance = Loadable({
  loader: () => import("./pages/management/clearance/Clearance"),
  loading: LoadingComponent,
});

const ClearanceForm = Loadable({
  loader: () => import("./pages/management/clearance/ClearanceForm"),
  loading: LoadingComponent,
});

const Reimbursement = Loadable({
  loader: () => import("./pages/management/approvals/Reimbursement"),
  loading: LoadingComponent,
})

const DocuSealComp = Loadable({
  loader: () => import("./pages/docuSeal"),
  loading: LoadingComponent,
})

const setMenuComponent = (label, permission) => {
  switch (label) {
    // main
    case "dashboard":
      return <Dashboard permission={permission} />;
    case "system-configuration":
      return <SystemConfiguration permission={permission} />;
    case "events":
      return <EventsCalendar permission={permission} />;
    case "payment-facility":
      return <PaymentFacility />;
    case "my-statistics":
      return <Navigate to="/my-statistics/candidate" state={{ permission }} />;
    case "my-task-list":
      return <MyTaskList />;
    case "referrals":
      return <Navigate to="/referrals/referrals/1/10" state={{ permission }} />;

    // main -> sub
    case "account/users":
      // return <Navigate to="/accounts/users/user-list" state={{ permission }} />;
      return <AccountUser />;
    case "account/roles-permission":
      return <AccountRolesAndPermissions />;
    case "account/company-profile":
      return <CompanyProfile />;

    // main -> sub -> sub
    case "management/candidate-management/candidate-list":
      return (
        <Navigate
          to="/management/candidate-management/candidate-list/active/1/10"
          state={{ permission }}
        />
      );
    case "management/candidate-management/advanced-search":
      return <AdvanceSearch />;
    case "management/candidate-management/add-candidate":
      return <NewCandidate permission={permission} />;
    case "management/client-management/client-list":
      return (
        <Navigate
          to="/management/client-management/client-list/current/1/10"
          state={{ permission }}
        />
      );
    case "management/accounting-management/compensation-list":
      return (
        <Navigate
          to="/management/accounting-management/compensation-list/bonus/1/10"
          state={{ permission }}
        />
      );
    case "management/client-management/request-list":
      return (
        <Navigate
          to="/management/client-management/request-list/overtime/1/10"
          state={{ permission }}
        />
      );
    case "management/client-management/add-client":
      return <AddClient permission={permission} />;
    case "management/hr-management/previous-employee-list" ||
      "management/hr-management/inactive-employee-list":
      return <PreviousEmployeeList permission={permission} />;
    case "management/hr-management/reports":
      return <Reports />;
    case "management/asset-management/asset-tracker/internal":
      return <ManagementAssetManagementListInternal />;
    case "management/asset-management/asset-tracker/external":
      return <ManagementAssetManagementListExternal />;
    case "management/asset-management/asset-inventory":
      return <ManagementAssetInventory />
    // case "management/asset-management/request/internal":
    //   return <ManagementAssetManagementRequestInternal />;
    // case "management/asset-management/request/external":
    //   return <ManagementAssetManagementRequestExternal />;
    case "management/asset-management/settings":
      return <ManagementSettings />;
    case "management/hr-management/settings":
      return (
        <Navigate to="/management/hr-management/settings/job-type/list/1/10" />
      );
    case "management/employee-relations/learning-development":
      return (
        <Navigate to="/management/employee-relations/learning-development/1/10" />
      );
    case "management/hr-management/request-interview":
      return <Navigate to="/management/hr-management/request-interview/1/10" />;
    case "management/commission-management/transaction-history":
      return <TransactionHistory />;
    case "management/commission-management/my-statistic-sales":
      return <MyStatisticSales />;
    case "management/clearance/clearance-form":
      return <ClearanceForm permission={permission} />;
    case "management/approvals/clearance":
      return (
        <Navigate
          to="/management/approvals/clearance/1/10"
          state={{ permission }}
        />
      );
    case "management/approvals/filed-request":
      return (
        <Navigate
          to="/management/approvals/filed-request/LEAVE"
          state={{ permission }}
        />
      );
    case "management/approvals/reimbursement":
      return <Reimbursement />;
    case "management/accounting-management/billing-history":
      return <BillingHistory />;
    case "management/accounting-management/billing":
      return <Billing />;
    case "management/accounting-management/invoices":
      return <Invoices />;

    //main -> sub -> sub -> sub
    case "management/hr-management/interviews-job-offer-list/internal-candidate":
      return (
        <Navigate
          to="/management/hr-management/interviews-job-offer-list/internal-candidate/my-interview/1/10"
          state={{ permission }}
        />
      );
    case "management/hr-management/interviews-job-offer-list/external-candidate":
      return (
        <Navigate
          to="/management/hr-management/interviews-job-offer-list/external-candidate/my-interview/1/10"
          state={{ permission }}
        />
      );
    case "management/hr-management/hired-employee-list/internal-employee":
      return (
        <Navigate
          to="/management/hr-management/hired-employee-list/internal-employee/hired/1/10"
          state={{ permission }}
        />
      );
    case "management/hr-management/hired-employee-list/external-employee":
      return (
        <Navigate
          to="/management/hr-management/hired-employee-list/external-employee/hired/1/10"
          state={{ permission }}
        />
      );
    case "management/hr-management/timekeeping/time-record":
      return <TimeRecord />;
    case "management/hr-management/timekeeping/work-schedule":
      return <WorkSchedule />;
    case "management/hr-management/timekeeping/employee-leave-credit":
      return <EmployeeLeaveCredits />;
    case "management/accounting-management/commission-management/st-personnel":
      return <STPersonnel />;
    case "management/accounting-management/commission-management/commission":
      return <Commission />;
    case "management/accounting-management/commission-management/client-assignment":
      return <Clients />;
    case "management/accounting-management/commission-management/employees":
      return <Employees />;

    // case "management/employee-relations/learning-development":
    //   return <LearningDevelopment />;
    default:
      return <ComingSoon showCountdown={false} isInside={true} />;
  }
};

function App({ clientAction }) {
  const { menus } = useSelector((state) => state.auth);
  const otpmenus = useSelector((state) => state.verifyotp).menus;
  const [isSplash, setSplash] = useState(true);

  const menu = getUser()?.menus || otpmenus || menus;

  useEffect(() => {
    setTimeout(() => {
      setSplash(false);
    }, 3600);
  }, []);

  useEffect(() => {
    if (process.env.APP_PROD === 1) {
      DebugMode(false, true);
    }
  }, []);

  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={2500}>
      <DataContextProvider>
        <Routes>
          {/* private routes */}
          <Route path="/" element={<ProtectedRoutes isSplash={isSplash} />}>
            {menu?.map((mainLabel, i) =>
              mainLabel?.menu?.map((menu, im) => {
                if (menu.sub_menu && menu.sub_menu.length > 0) {
                  return (
                    // <Route path=":menuName" key={im}>
                    <Route path={replaceAndSpace(menu.label)} key={im}>
                      {menu.sub_menu.map((sub, is) => {
                        if (sub.sub_level && sub.sub_level.length > 0) {
                          return (
                            <Route path={replaceAndSpace(sub.label)} key={is}>
                              {sub.sub_level.map((subSub, iss) => {
                                if (
                                  subSub.sub_level_2 &&
                                  subSub.sub_level_2.length > 0
                                ) {
                                  return (
                                    <Route
                                      path={replaceAndSpace(subSub.label)}
                                      key={iss}
                                    >
                                      {subSub.sub_level_2.map(
                                        (subSubSub, isss) => (
                                          <Route
                                            path={replaceAndSpace(
                                              subSubSub.label
                                            )}
                                            key={isss}
                                            element={setMenuComponent(
                                              `${replaceAndSpace(
                                                menu.label
                                              )}/${replaceAndSpace(
                                                sub.label
                                              )}/${replaceAndSpace(
                                                subSub.label
                                              )}/${replaceAndSpace(
                                                subSubSub.label
                                              )}`,
                                              subSubSub.permission
                                            )}
                                          />
                                        )
                                      )}
                                    </Route>
                                  );
                                }

                                return (
                                  <Route
                                    path={replaceAndSpace(subSub.label)}
                                    // path=":subName"
                                    key={iss}
                                    element={setMenuComponent(
                                      `${replaceAndSpace(
                                        menu.label
                                      )}/${replaceAndSpace(
                                        sub.label
                                      )}/${replaceAndSpace(subSub.label)}`,
                                      subSub.permission
                                    )}
                                  />
                                );
                              })}
                            </Route>
                          );
                        }

                        return (
                          <Route
                            path={replaceAndSpace(sub.label)}
                            // path=":subName"
                            key={is}
                            element={setMenuComponent(
                              `${replaceAndSpace(menu.label)}/${replaceAndSpace(
                                sub.label
                              )}`,
                              sub.permission
                            )}
                          />
                        );
                      })}
                    </Route>
                  );
                }

                return (
                  <Route
                    path={
                      menu?.label === "Dashboard"
                        ? "/"
                        : replaceAndSpace(menu?.label)
                    }
                    // path={menu.label === "Dashboard" ? "/" : ":menuName"}
                    key={im}
                    element={setMenuComponent(
                      replaceAndSpace(menu?.label),
                      menu.permission
                    )}
                  />
                );
              })
            )}
            <Route
              path="/management/candidate-management/candidate-list/:tab/:page/:entries"
              element={<CandidateManagement />}
            />
            <Route
              path="/management/candidate-management/candidate/:id"
              element={<ViewCandidate />}
            />
            <Route
              path="/management/client-management/client-list/:category/:page/:entries"
              element={<ClientManagement />}
            />
            <Route
              path="/management/accounting-management/compensation-list/:category/:page/:entries"
              element={<CompensationList />}
            />
            <Route
              path="/management/client-management/client/:id/profile/:tab"
              element={<ClientProfile />}
            />
            <Route
              path="/management/client-management/client/:id/schedules"
              element={<ClientSchedules />}
            />
            <Route
              path="/management/hr-management/interviews-job-offer-list/internal-candidate/:currentTab/:page/:entries"
              element={<HRManagement canType="Internal" />}
            />
            <Route
              path="/management/hr-management/interviews-job-offer-list/external-candidate/:currentTab/:page/:entries"
              element={<HRManagement canType="External" />}
            />
            <Route
              path="/management/hr-management/hired-employee-list/internal-employee/:tab/:page/:entries"
              element={<Hired hiredType="Internal" />}
            />
            <Route
              path="/management/hr-management/hired-employee-list/external-employee/:tab/:page/:entries"
              element={<Hired hiredType="External" />}
            />
            <Route
              path="/management/hr-management/new-job-offer/:id"
              element={<NewJobOffer />}
            />
            <Route
              path="/management/hr-management/candidate/:id/:interview_id"
              element={<ViewResumeCandidate />}
            />
            <Route
              path="/management/hr-management/hired-employee-list/:id/:user_id"
              element={<EditHiredInformation />}
            />
            <Route
              path="/management/hr-management/previous-employee-list/:id/:user_id"
              element={<EditHiredInformation />}
            />
            <Route
              path="/management/candidate-management/candidate/:id"
              element={<ViewCandidate />}
            />
            <Route
              path={
                "/management/hr-management/settings/:tab/:step/:page/:entries"
              }
              element={<HRSettings />}
            />
            <Route
              path="/management/hr-management/settings/:tab/:step"
              element={<HRSettings />}
            />
            <Route
              path="/management/hr-management/settings/:tab"
              element={<HRSettings />}
            />
            <Route
              path="/management/employee-relations/learning-development/:page/:limit"
              element={<LearningDevelopment />}
            />
            <Route
              path="/management/approvals/filed-request/:tab"
              element={<FiledRequest />}
            />
            <Route
              path="/my-statistics/:tab"
              element={<MyStatistics />}
            />
            <Route
              path="/referrals/:tab/:page/:entries"
              element={<Referrals />}
            />
            {/* temporary route for request interview */}
            <Route
              path="/management/hr-management/request-interview/:page/:entries"
              element={<RequestInterview />}
            />
            <Route
              path="/management/approvals/clearance/:page/:entries"
              element={<Clearance />}
            />
            <Route
              path="/management/clearance/clearance-form"
              element={<ClearanceForm />}
            />
            <Route
              path="/my-task-list"
              element={<MyTaskList />}
            />
            <Route
              path="/docu-seal"
              element={<DocuSealComp />}
            />
            {/* <Route path="/accounts/users" element={<AccountUser />} /> */}
            {/* <Route path="/accounts/users/:tab" element={<AccountUser />} /> */}
          </Route>
          {/* public routes */}
          <Route
            path="redirect-google-calendar"
            element={<PublicRoutes notFound={true} isSplash={isSplash} />}
          >
            <Route
              path="/redirect-google-calendar"
              element={<RedirectGoogleCalendar />}
            />
          </Route>
          <Route path="login" element={<PublicRoutes isSplash={isSplash} />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route
            path="forgot-password"
            element={<PublicRoutes hasBgName={false} isSplash={isSplash} />}
          >
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>
          <Route
            path="/reset-password"
            element={<PublicRoutes hasBgName={false} isSplash={isSplash} />}
          >
            <Route path="/reset-password/:token" element={<ResetPassword />} />
          </Route>
          <Route
            path="/change-password"
            element={<PublicRoutes hasBgName={false} isSplash={isSplash} />}
          >
            <Route
              path="/change-password"
              element={<ResetPassword updatePassword={true} />}
            />
          </Route>
          <Route
            path="*"
            element={
              <PublicRoutes
                notFound={true}
                setSplash={setSplash}
                isSplash={isSplash}
              />
            }
          >
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route
            path="/meeting/zoom/:meetingId/:id/:position_id/:meetingNumber/:password/:isHost"
            element={<Zoom />}
          />
        </Routes>
        <p
          style={{
            position: "fixed",
            bottom: 2,
            right: 15,
            fontSize: ".7rem",
            color: "#ccc",
          }}
        >
          {"v. " + process.env.REACT_APP_VERSION}
        </p>
      </DataContextProvider>
    </SnackbarProvider>
  );
}

export default App;
