import { createSlice } from "@reduxjs/toolkit";

export const initialStateValue = {
    id: null,
    user_id: null,
    first_name: null,
    last_name: null,
    employee_status: null,
    hired_date: null,
    attrited_date: null,
    resigned_date: null,
    terminated_date: null,
    phone: null,
    email: null,
    profile: null,
    country: null,
    country_flag: null,
    currency_salary: null,
    expected_salary: null,
    currency_compensation: null,
    compensation_amount: null,
    matching_rate: null,
    interest_count: null,
    manage_team: null,
    direct_people: null,
    indirect_people: null,
    job_role: {
      id: null,
      resume_id: null,
      position_id: null,
      position: null,
      level_id: null,
      default: null,
    },
    availability: null,
    interested: null,
    rating: null,
    client_id: null,
    client_country: null,
    client_name: null,
    client_position: null,
    client_rate: null,
    client_hired: null,
    client_for_interview: null,
    client_for_joboffer: null,
    start_date: null,
  };

export const jobofferSlice = createSlice({
    name: "joboffer",
    initialState: { value: initialStateValue },
    reducers: {
        getData: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const {getData} = jobofferSlice.actions;

export default jobofferSlice.reducer;